import { createSlice } from '@reduxjs/toolkit';

interface CreateState {
    startDate: any;
    endDate: any;
    formateStartDate: string;
    formateEndDate: string;
    startConfirm: boolean;
    endConfirm: boolean;
    startTime: string;
    endTime: string;
    mainStartDate: string;
    mainEndDate: string;
}

const initialState: CreateState = {
    startDate: null,
    endDate: null,
    formateStartDate: '',
    formateEndDate: '',
    startConfirm: false,
    endConfirm: false,
    startTime: '00:00',
    endTime: '00:00',
    mainStartDate: '',
    mainEndDate: '',
};

const jobCreateSlice = createSlice({
    name: 'jobCreate',
    initialState,
    reducers: {
        jobCreateData(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const { jobCreateData } = jobCreateSlice.actions;
export default jobCreateSlice.reducer;
