import React, { useEffect, useState } from 'react';
import starIcon from 'src/assets/svgs/starIcon.svg';
import uploadIcon from 'src/assets/svgs/uploadIcon.svg';
import TextWithIconButton from 'src/components/Common/TextWithIconButton';
import rightIcon from 'src/assets/svgs/rightIcon.svg';
import closeIcon from 'src/assets/svgs/closeIcon1.svg';
import { toaster } from 'src/utils/toaster';
import { jobComplete } from 'src/services/jobDetail';
import { getCurrentDateTime } from 'src/utils/function';
import backButtonImg from 'src/assets/img/employeeBackBtnImg.png';

const FinishJob = ({ detailList, jobFinishSuccess, isBack = () => {} }: any) => {
    const [employeeData, setEmployeeData] = useState<any>([]);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [unCheckListEmployee, setUnCheckListEmployee] = useState([]);
    const [error, setError] = useState('');
    useEffect(() => {
        if (unCheckListEmployee?.length !== 0) {
            makeAllCheckUncheck(false);
        }
    }, [unCheckListEmployee]);

    useEffect(() => {
        if (detailList?.employees) {
            // makeAllCheckUncheck(false);
            const filterUncheckedData = detailList?.employees?.filter((item: any, index: number) => item?.isJobDone === false);
            setUnCheckListEmployee(filterUncheckedData);
        }
    }, [detailList]);
    useEffect(() => {
        if (employeeData?.length !== 0) {
            const checkAllChecked = employeeData.every((item: any) => item.checked);
            setCheckAll(checkAllChecked);
        }
    }, [employeeData]);
    const makeAllCheckUncheck = (checkValue: boolean) => {
        unCheckListEmployee?.forEach((data: any, index: number) => {
            setEmployeeData((prevData: any) => {
                const newData = [...prevData];
                newData[index] = {
                    ...newData[index],
                    checked: checkValue,
                };
                return newData;
            });
        });
    };
    const handleFileChange = async (event: any, index: number) => {
        const file = event.target.files[0];
        // const base64 = await pdfToBase64(file);
        setEmployeeData((prevData: any) => {
            const newData = [...prevData];
            newData[index] = {
                ...newData[index],
                invoice: {
                    file,
                },
            };
            return newData;
        });
    };

    const handleCloseFileChange = (index: number) => {
        setEmployeeData((prevData: any) => {
            const newData = [...prevData];
            newData[index] = {
                ...newData[index],
                invoice: {
                    file: {
                        name: '',
                    },
                },
            };
            return newData;
        });
    };

    const handlePhotoOptionChange = (event: any, index: number) => {
        const value = event.target.value === 'true' ? true : false;
        setEmployeeData((prevData: any) => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], havePhoto: value };
            return newData;
        });
    };

    const handleEmployeeLateOptionChange = (event: any, index: number) => {
        const value = event.target.value === 'true' ? true : false;
        setEmployeeData((prevData: any) => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], employeeLate: value };
            return newData;
        });
    };

    const handleRatingChange = (event: any, index: number, employeeId: number) => {
        let value = event.target.value;
        if (value.length > 1) {
            return;
        } else if (value.length === 1) {
            if (!/^[1-5]$/.test(value)) {
                return true;
            }
        }
        value = value.replace(/\D/g, '');

        setEmployeeData((prevData: any) => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], rate: value ? Number(value) : '', employeeId: employeeId };
            return newData;
        });
    };

    const handleNotesChange = (event: any, index: number) => {
        const value = event.target.value;
        setEmployeeData((prevData: any) => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], note: value };
            return newData;
        });
    };
    const checkValidation = () => {
        const checkedEmployees = employeeData.filter((employee: any) => employee.checked);
        if (checkedEmployees?.length !== 0) {
            const checkedAllFieldRequired = checkedEmployees.every(
                (employee: any) =>
                    employee?.hasOwnProperty('employeeId') &&
                    employee?.hasOwnProperty('employeeLate') &&
                    employee?.hasOwnProperty('havePhoto') &&
                    employee?.hasOwnProperty('invoice') &&
                    employee?.invoice?.hasOwnProperty('file') &&
                    // !employee?.invoice?.hasOwnProperty('data') ||
                    employee?.hasOwnProperty('rate') &&
                    employee?.rate &&
                    employee?.invoice?.file?.name,
            );
            if (checkedAllFieldRequired) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };
    const finsishJob = async () => {
        const formData = new FormData();
        formData.append('jobId', detailList?.id);
        // formData.append('endDate', detailList?.endDate ? utcConverter(detailList?.endDate, 'YYYY-MM-DD HH:mm:ss') : getCurrentDateTime('YYYY-MM-DD HH:mm:ss'));
        formData.append('endDate', getCurrentDateTime('YYYY-MM-DD HH:mm:ss'));
        const filterCheckedData = employeeData?.filter((item: any, index: number) => item?.checked === true);
        const newEmployeeData = filterCheckedData?.map(({ invoice, checked, ...rest }: any) => ({ ...rest }));
        const allIdsPresent = newEmployeeData.every((item1: any) => detailList?.timeSheets.some((sheet: any) => sheet.employeeId === item1.employeeId && sheet?.id));
        if (!allIdsPresent) {
            setError(`Please first fill in the select ${newEmployeeData?.length === 1 ? 'employee timesheet' : 'employees timesheets'}`);
            return;
        }
        formData.append(`employeeData`, JSON.stringify(newEmployeeData));
        filterCheckedData.forEach(({ invoice }: any) => {
            formData.append('image', invoice?.file); // 'image' as the key
        });

        setBtnDisabled(true);
        const jobFinish = await jobComplete(formData);
        if (jobFinish.status === 200) {
            // toaster('success', jobFinish.data.message);
            jobFinishSuccess();
        } else {
            toaster('error', jobFinish.data.message);
            setBtnDisabled(false);
            if (jobFinish.data.message === 'Please fill the employees timesheets.') {
                setError(`Please first fill in the select ${newEmployeeData?.length === 1 ? 'employee timesheet' : 'employees timesheets'}`);
            }
        }
    };
    const checkBoxChange = (e: any, index: number) => {
        setEmployeeData((prevData: any) => {
            const newData = [...prevData];
            newData[index] = {
                ...newData[index],
                checked: e.target.checked,
            };
            return newData;
        });
    };
    const checkBoxAllChange = (e: any) => {
        makeAllCheckUncheck(e.target.checked);
        setCheckAll(e.target.checked);
    };
    return (
        <>
            <div className="max-lg:bg-white max-lg:rounded-xl max-lg:p-5">
                <div className="lg:px-6 ">
                    <div className="flex flex-row gap-2">
                        <div className="flex items-center gap-2 lg:hidden">
                            <button className="max-md:h-6 max-md:w-6 max-lg:h-[30px] max-lg:w-[30px]" onClick={() => isBack()}>
                                <img src={backButtonImg} alt="backaerrowimg" />
                            </button>
                        </div>
                        <div>
                            <h2 className="font-black text-lg">Finish Job</h2>
                            <p className="text-sm mb-3">in order to complete the job, please fill required information</p>
                        </div>
                    </div>
                    <div className="">
                        <input
                            type="checkbox"
                            id="allEmployee"
                            name="allEmployee"
                            value="all"
                            checked={checkAll || false}
                            className="accent-black cursor-pointer"
                            onChange={(e) => checkBoxAllChange(e)}
                        />
                    </div>
                </div>
                <div className="mb-4 mt-2 lg:px-6 finish_modal_inner max-h-[366px] overflow-y-auto">
                    {unCheckListEmployee?.length !== 0 &&
                        employeeData &&
                        unCheckListEmployee?.map((data: any, index: number) => (
                            <div key={index} className={`${unCheckListEmployee?.length === 1 ? '' : 'border-b-2 last:border-none'} mb-4 first:border-t-2`}>
                                <div className="mt-2 flex justify-between items-center  first:mt-4 mb-4">
                                    <div className="flex  gap-2">
                                        <input
                                            type="checkbox"
                                            id={`employee-${index}`}
                                            name={`employee-${index}`}
                                            value={data?.employeeId}
                                            checked={employeeData[index]?.checked || false}
                                            className="accent-black cursor-pointer "
                                            onChange={(e) => checkBoxChange(e, index)}
                                        />

                                        <div className="flex gap-2 items-center">
                                            <p className="lg:text-base max-md:text-xs font-medium">{`${data?.name} ${data?.surname}`}</p>
                                            <div className="bg-gray-200 h-6 flex border rounded-xl items-center px-2 text-xs font-medium text-black80">{data?.jobType}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="image-upload px-3 py-2 bg-gray10 rounded-xl cursor-pointer max-md:px-2 max-md:w-[115px] w-36">
                                            {employeeData[index]?.invoice?.file?.name ? (
                                                <div className="flex items-center justify-center gap-1 ">
                                                    <p className="text-sm truncate">{employeeData[index]?.invoice?.file?.name}</p>
                                                    <img src={closeIcon} alt="uploadImage" className="ms-1 h-[10px] w-[10px] cursor-pointer" onClick={() => handleCloseFileChange(index)} />
                                                </div>
                                            ) : (
                                                <>
                                                    <label htmlFor={`file-input-${index}`} className="flex items-center justify-center cursor-pointer gap-1">
                                                        <img src={uploadIcon} alt="uploadImage" className="max-md:w-4 h-5 w-6 cursor-pointer" />
                                                        <p className="text-sm max-md:text-[12px] text-secondary">Upload Invoice</p>
                                                    </label>
                                                    <input id={`file-input-${index}`} type="file" accept="" className="hidden" onChange={(e) => handleFileChange(e, index)} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <p className=" text-sm font-medium">Do you have Photo?</p>
                                    <div>
                                        <label className="me-3">
                                            <input
                                                type="radio"
                                                value="true"
                                                className="me-2 w-4 h-4 accent-black cursor-pointer"
                                                checked={employeeData[index]?.havePhoto === true}
                                                onChange={(e) => handlePhotoOptionChange(e, index)}
                                            />
                                            Yes
                                        </label>

                                        <label className="me-2">
                                            <input
                                                type="radio"
                                                value="false"
                                                className="me-2 w-4 h-4 accent-black cursor-pointer"
                                                checked={employeeData[index]?.havePhoto === false}
                                                onChange={(e) => handlePhotoOptionChange(e, index)}
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <p className="text-sm font-medium">Was employee Late?</p>
                                    <div>
                                        <label className="me-3">
                                            <input
                                                type="radio"
                                                value="true"
                                                className="me-2 w-4 h-4 accent-black cursor-pointer"
                                                checked={employeeData[index]?.employeeLate === true}
                                                onChange={(e) => handleEmployeeLateOptionChange(e, index)}
                                            />
                                            Yes
                                        </label>

                                        <label className="me-2">
                                            <input
                                                type="radio"
                                                value="false"
                                                className="me-2 w-4 h-4 accent-black cursor-pointer"
                                                checked={employeeData[index]?.employeeLate === false}
                                                onChange={(e) => handleEmployeeLateOptionChange(e, index)}
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div className="flex mb-4 max-md:flex max-md:flex-col max-md:gap-3">
                                    <div className="relative me-2 max-md:w-full max-md:text-center">
                                        <input
                                            className="max-md:w-full max-md:justify-between max-md:flex w-32 h-10 ps-8 pe-3 py-2 bg-white text-base focus-visible:outline-none focus:border focus:border-black rounded-xl border border-gray60 placeholder:text-sm placeholder:justify-center"
                                            placeholder="Add Rating"
                                            // disabled={nextStep ? true : false}
                                            onChange={(e) => handleRatingChange(e, index, data.employeeId)}
                                            type="text"
                                            value={employeeData[index]?.rate || ''}
                                        />
                                        <img className="w-4 h-4 absolute max-md:flex max-md:justify-center left-3 top-3" src={starIcon} alt="starIcon" />
                                    </div>
                                    <input
                                        type="text"
                                        className="bg-gray10 rounded-xl pl-4 w-full placeholder:text-sm max-md:h-10 "
                                        placeholder="Notes (optional)"
                                        onChange={(e) => handleNotesChange(e, index)}
                                    />
                                </div>
                            </div>
                        ))}
                </div>
                <div className="px-6 max-lg:hidden">
                    <TextWithIconButton
                        btnClassName={`w-full h-12 lg:px-6 py-4 ${checkValidation() || btnDisabled ? 'bg-Green40' : 'bg-Green80'} rounded-3xl  shadow justify-center items-center gap-2 inline-flex`}
                        textClassName="text-white text-sm font-bold font-['Satoshi Variable'] uppercase"
                        imgClassName="w-6 h-6 relative"
                        icon={rightIcon}
                        textName="Finish the job"
                        altName="righticon"
                        onClick={() => {
                            finsishJob();
                        }}
                        disabled={checkValidation() || btnDisabled}
                        isLoader={btnDisabled}
                        color={'white'}
                        size={35}
                    />
                </div>
            </div>
            <div className="mt-6 lg:hidden">
                <TextWithIconButton
                    btnClassName={`w-full h-12 px-6 py-4 ${checkValidation() || btnDisabled ? 'bg-Green40' : 'bg-Green80'} rounded-3xl  shadow justify-center items-center gap-2 inline-flex`}
                    textClassName="text-white text-sm font-bold font-['Satoshi Variable'] uppercase"
                    imgClassName="w-6 h-6 relative"
                    icon={rightIcon}
                    textName="Finish the job"
                    altName="righticon"
                    onClick={() => {
                        finsishJob();
                    }}
                    disabled={checkValidation() || btnDisabled}
                    isLoader={btnDisabled}
                    color={'white'}
                    size={35}
                />
            </div>
            {error && <div className="text-red-600 mt-2 text-center">{error}</div>}
        </>
    );
};

export default FinishJob;
