import { API } from 'src/constants/api';
import { axiosInterceptor } from 'src/utils/interceptors';

export const jobDetailList = async (payload?: any) => {
    const uri = API.JobDetail.list + payload;
    const response = await axiosInterceptor.get(uri);
    return response;
};

export const updateJob = async (payload?: any) => {
    const uri = API.JobDetail.update + payload?.jobDetailId;
    const response = await axiosInterceptor.post(uri, payload);
    return response;
};

export const addTimeSheet = async (payload?: any) => {
    const uri = API.JobDetail.addTimeSheet;
    const response = await axiosInterceptor.post(uri, payload);
    return response;
};

export const updateTimeSheet = async (payload?: any) => {
    const uri = API.JobDetail.updateTimeSheet + payload?.timeSheetId;
    const response = await axiosInterceptor.post(uri, payload);
    return response;
};

export const jobComplete = async (payload?: any) => {
    const uri = API.JobDetail.jobComplete;
    const response = await axiosInterceptor.post(uri, payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
};

export const updateInvoice = async (payload?: any) => {
    const uri = API.JobDetail.updateInvoice;
    const response = await axiosInterceptor.post(uri, payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
};
