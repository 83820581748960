import { API } from 'src/constants/api';
import { axiosInterceptor } from 'src/utils/interceptors';

export const doLogin = async (payload: any) => {
    const uri = API.Login.login;
    const response = await axiosInterceptor.post(uri, payload);
    return response;
};

export const doForgotPassword = async (payload: any) => {
    const uri = API.Login.forgotPassword;
    const response = await axiosInterceptor.post(uri, payload);
    return response;
};
