import React from 'react';
import westImg from 'src/assets/img/westImg.png';
import fsImg from 'src/assets/img/fsImg.png';
import logImg from 'src/assets/img/logImg.png';
import { TiFlash } from 'react-icons/ti';
import { FaCircleExclamation } from 'react-icons/fa6';
import upDirectionAerrow from 'src/assets/svgs/upDirectionAerrow.svg';
import downDirectionAerrow from 'src/assets/svgs/downDirectionAerrow.svg';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { Tooltip } from 'flowbite-react';
import { breakPoints } from 'src/constants/general';
import { makeHoursAndMinutes } from 'src/utils/function';

const ResponsiveAdminListAccordion = ({ adminListData, openAcoordionResponsive, setOpenAcoordionResponsive }: any) => {
    const { isMobile }: any = useDeviceDetect();
    return (
        <>
            {adminListData?.length !== 0 &&
                adminListData?.map((data: any, index: number) => (
                    <>
                        <div className={`md:hidden w-full flex p-[10px] bg-white flex-col rounded-3xl mt-3 ${openAcoordionResponsive === index ? '' : 'border-b-2 border-[#C1C1C1]'}`}>
                            <button className={`flex items-center justify-between w-full`} onClick={() => setOpenAcoordionResponsive((preavState: any) => (preavState === index ? null : index))}>
                                <div className="flex items-center gap-2 w-1/4">
                                    <div className="text-black max-md:text-xs font-bold font-['Satoshi Variable'] uppercase max-lg:text-sm">{data?.adminFullName}</div>
                                </div>
                                <div className="max-md:flex flex-col font-['Satoshi Variable'] gap-1 w-1/2">
                                    <div className="flex justify-center">
                                        <span className="text-[#627BE8] font-bold text-xs">Spent Time :</span>
                                        <span className="font-medium text-xs ml-1">{makeHoursAndMinutes(Number(data?.total_screening_time_in_min))}</span>
                                    </div>
                                </div>
                                <div className={`w-1/7 flex justify-end`}>
                                    <img className="w-[30px] h-[30px]" src={`${openAcoordionResponsive === index ? upDirectionAerrow : downDirectionAerrow}`} alt="img" />
                                </div>
                            </button>
                            <div className={` mt-3 border-t-2 border-[#C1C1C1] p-[10px] ${openAcoordionResponsive === index ? ' max-md:block hidden' : 'hidden'}`}>
                                <div className="mb-2 flex items-center">
                                    <span className="w-[120px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Jobs Created</span>
                                    <span className="mr-[10px] ml-[10px] text-[#627BE8]">:</span>
                                    <span className="font-['Satoshi Variable'] font-medium text-xs">{data?.total_jobs_created}</span>
                                </div>
                                <div className="mb-2 flex items-center">
                                    <span className="w-[120px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Jobs Completed</span>
                                    <span className="mr-[10px] ml-[10px] text-[#627BE8]">:</span>
                                    <span className="font-['Satoshi Variable'] font-medium text-xs">{data?.total_jobs_completed}</span>
                                </div>
                                <div className="mb-2 flex items-center">
                                    <span className="w-[120px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Jobs Cancelled</span>
                                    <span className="mr-[10px] ml-[10px] text-[#627BE8]">:</span>
                                    <span className="font-['Satoshi Variable'] font-medium text-xs">{data?.total_jobs_cancelled}</span>
                                </div>
                                <div className="mb-2 flex items-center">
                                    <span className="w-[120px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Worker Registered</span>
                                    <span className="mr-[10px] ml-[10px] text-[#627BE8]">:</span>
                                    <span className="font-['Satoshi Variable'] font-medium text-xs">{data?.total_employees_created}</span>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`max-lg:flex max-md:hidden hidden w-full  p-[10px] bg-white flex-col rounded-3xl mt-3 ${openAcoordionResponsive === index ? '' : 'border-b-2 border-[#C1C1C1]'}`}
                        >
                            <button className={`flex items-center justify-between w-full`} onClick={() => setOpenAcoordionResponsive((preavState: any) => (preavState === index ? null : index))}>
                                <div className="flex items-center gap-2 w-1/3">
                                    <div className="text-black max-md:text-xs font-bold font-['Satoshi Variable'] uppercase max-lg:text-sm">{data?.adminFullName}</div>
                                </div>
                                <div className="max-md:flex flex-col font-['Satoshi Variable'] gap-1 w-1/2 ">
                                    <div className="flex justify-center">
                                        <span className="text-[#627BE8] font-bold text-xs">Spent Time :</span>
                                        <span className="font-medium text-xs ml-2">{makeHoursAndMinutes(Number(data?.total_screening_time_in_min))}</span>
                                    </div>
                                </div>
                                <div className={`w-1/4 flex justify-end`}>
                                    <img className="w-[30px] h-[30px]" src={`${openAcoordionResponsive === index ? upDirectionAerrow : downDirectionAerrow}`} alt="img" />
                                </div>
                            </button>
                            <div className={`px-2.5 border-t-2 mt-3 border-[#C1C1C1] justify-between items-center pt-2.5 ${openAcoordionResponsive === index ? ' max-lg:flex hidden' : 'hidden'}`}>
                                <div className="">
                                    <div className="mb-2 flex items-center">
                                        <span className="w-[150px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Jobs Created</span>
                                        <div className="">
                                            <span className="mx-[10px] text-[#627BE8]">:</span>
                                            <span className="font-['Satoshi Variable'] font-medium text-xs">{data?.total_jobs_created}</span>
                                        </div>
                                    </div>
                                    <div className="mb-2 flex items-center">
                                        <span className="w-[150px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Jobs Completed</span>
                                        <div className="flex items-center">
                                            <span className="mx-[10px] text-[#627BE8]">:</span>
                                            <span className="font-['Satoshi Variable'] font-medium text-xs">{data?.total_jobs_completed}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="mb-2 flex items-center">
                                        <span className="w-[150px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Jobs Cancelled</span>
                                        <div className="">
                                            <span className="mx-[10px] text-[#627BE8]">:</span>
                                            <span className="font-['Satoshi Variable'] font-medium text-xs">{data?.total_jobs_cancelled}</span>
                                        </div>
                                    </div>
                                    <div className="mb-2 flex items-center">
                                        <span className="w-[150px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Worker Registered</span>
                                        <div className="flex items-center">
                                            <span className="mx-[10px] text-[#627BE8]">:</span>
                                            <span className="font-['Satoshi Variable'] font-medium text-xs">{data?.total_employees_created}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
        </>
    );
};
export default ResponsiveAdminListAccordion;
