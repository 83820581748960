import React from 'react';

export const HomeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
        <path
            d="M1.6875 9.49981L8.403 2.78356C8.733 2.45431 9.267 2.45431 9.59625 2.78356L16.3125 9.49981M3.375 7.81231V15.4061C3.375 15.8718 3.753 16.2498 4.21875 16.2498H7.3125V12.5936C7.3125 12.1278 7.6905 11.7498 8.15625 11.7498H9.84375C10.3095 11.7498 10.6875 12.1278 10.6875 12.5936V16.2498H13.7812C14.247 16.2498 14.625 15.8718 14.625 15.4061V7.81231M6.1875 16.2498H12.375"
            stroke="#EFF0F1"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const RecivedJobIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M4 4.58533V4C4 3.60218 4.15804 3.22064 4.43934 2.93934C4.72064 2.65804 5.10218 2.5 5.5 2.5H10.5C10.8978 2.5 11.2794 2.65804 11.5607 2.93934C11.842 3.22064 12 3.60218 12 4V4.58533M4 4.58533C4.15667 4.53 4.32467 4.5 4.5 4.5H11.5C11.6753 4.5 11.8433 4.53 12 4.58533M4 4.58533C3.70748 4.68876 3.45422 4.88035 3.27512 5.13371C3.09603 5.38707 2.99991 5.68973 3 6V6.58533M12 4.58533C12.2925 4.68876 12.5458 4.88035 12.7249 5.13371C12.904 5.38707 13.0001 5.68973 13 6V6.58533M3 6.58533C3.15667 6.53 3.32467 6.5 3.5 6.5H12.5C12.6703 6.4998 12.8394 6.52866 13 6.58533M3 6.58533C2.41733 6.79133 2 7.34667 2 8V12C2 12.3978 2.15804 12.7794 2.43934 13.0607C2.72064 13.342 3.10218 13.5 3.5 13.5H12.5C12.8978 13.5 13.2794 13.342 13.5607 13.0607C13.842 12.7794 14 12.3978 14 12V8C14.0001 7.68973 13.904 7.38707 13.7249 7.13371C13.5458 6.88035 13.2925 6.68876 13 6.58533"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const CompltedJobIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
            d="M3.86925 6.125C4.33942 6.125 4.7635 5.86483 5.054 5.495C5.50682 4.91728 6.07525 4.44044 6.72292 4.095C7.14467 3.871 7.51042 3.53733 7.68717 3.09458C7.81131 2.7844 7.87506 2.45335 7.875 2.11925V1.75C7.875 1.63397 7.9211 1.52269 8.00314 1.44064C8.08519 1.35859 8.19647 1.3125 8.3125 1.3125C8.6606 1.3125 8.99444 1.45078 9.24058 1.69692C9.48672 1.94306 9.625 2.2769 9.625 2.625C9.625 3.297 9.47334 3.93342 9.20325 4.50217C9.04809 4.82767 9.26567 5.25 9.62617 5.25H11.4497C12.0482 5.25 12.5843 5.65483 12.6478 6.25042C12.6741 6.49658 12.6875 6.74625 12.6875 7C12.6899 8.5962 12.1444 10.1449 11.1423 11.3872C10.9159 11.6684 10.5665 11.8125 10.206 11.8125H7.86334C7.58158 11.8125 7.301 11.767 7.03325 11.6783L5.21675 11.0717C4.94906 10.9826 4.66877 10.9373 4.38667 10.9375H3.444M8.3125 5.25H9.625H8.3125ZM3.444 10.9375C3.49242 11.0571 3.54492 11.1737 3.6015 11.2887C3.71642 11.522 3.556 11.8125 3.29642 11.8125H2.76675C2.24817 11.8125 1.7675 11.5103 1.61642 11.0145C1.41438 10.3514 1.31195 9.66195 1.3125 8.96875C1.3125 8.06283 1.48459 7.19775 1.79725 6.40325C1.97575 5.95175 2.43075 5.6875 2.91667 5.6875H3.53092C3.80625 5.6875 3.9655 6.01183 3.82259 6.2475C3.32436 7.06764 3.06158 8.00914 3.06309 8.96875C3.06309 9.66525 3.19842 10.3297 3.44459 10.9375H3.444Z"
            fill="#D0D7F8"
        />
        <path
            d="M3.86925 6.125C4.33942 6.125 4.7635 5.86483 5.054 5.495C5.50682 4.91728 6.07525 4.44044 6.72292 4.095C7.14467 3.871 7.51042 3.53733 7.68717 3.09458C7.81131 2.7844 7.87506 2.45335 7.875 2.11925V1.75C7.875 1.63397 7.9211 1.52269 8.00314 1.44064C8.08519 1.35859 8.19647 1.3125 8.3125 1.3125C8.6606 1.3125 8.99444 1.45078 9.24058 1.69692C9.48672 1.94306 9.625 2.2769 9.625 2.625C9.625 3.297 9.47334 3.93342 9.20325 4.50217C9.04809 4.82767 9.26567 5.25 9.62617 5.25H11.4497C12.0482 5.25 12.5843 5.65483 12.6478 6.25042C12.6741 6.49658 12.6875 6.74625 12.6875 7C12.6899 8.5962 12.1444 10.1449 11.1423 11.3872C10.9159 11.6684 10.5665 11.8125 10.206 11.8125H7.86334C7.58159 11.8125 7.301 11.767 7.03325 11.6783L5.21675 11.0717C4.94906 10.9826 4.66877 10.9373 4.38667 10.9375H3.444M3.444 10.9375C3.49242 11.0571 3.54492 11.1737 3.6015 11.2887C3.71642 11.522 3.556 11.8125 3.29642 11.8125H2.76675C2.24817 11.8125 1.7675 11.5103 1.61642 11.0145C1.41438 10.3514 1.31195 9.66195 1.3125 8.96875C1.3125 8.06283 1.48459 7.19775 1.79725 6.40325C1.97575 5.95175 2.43075 5.6875 2.91667 5.6875H3.53092C3.80625 5.6875 3.9655 6.01183 3.82259 6.2475C3.32436 7.06764 3.06158 8.00914 3.06309 8.96875C3.06309 9.66525 3.19842 10.3297 3.44459 10.9375H3.444ZM8.3125 5.25H9.625"
            stroke="#627BE8"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
