import React from 'react';
import searchIcon from 'src/assets/svgs/searchIcon.svg';
import blueSortIcon from 'src/assets/svgsResponsive/blueSortIcon.svg';
import bluePlusCircleIcon from 'src/assets/svgsResponsive/bluePlusCircleIcon.svg';
import blueCancelIcon from 'src/assets/svgsResponsive/blueCancelIcon.svg';

const ResponsiveHeaderContentBlock = ({ sortOnClick, closeOnClick, addNewOnClick, searchOnchange, searchInput, isDisabled, isNext }: any) => (
    <div className="hidden  justify-between items-center max-lg:flex">
        <span onClick={() => (isDisabled ? {} : sortOnClick())} className={`${isDisabled ? 'opacity-40' : ''}`}>
            <img src={blueSortIcon} alt="blueSortIcon" className="cursor-pointer max-md:w-[38px] max-md:h-[38px] max-lg:w-[50px] max-lg:h-[50px]" />
        </span>
        <div className="relative max-md:w-[68.53%] max-lg:w-[69%]">
            <input
                className={`w-full  px-5 py-[10px] pr-10 bg-white focus-visible:outline-none focus:border focus:border-primary 
                rounded-3xl border border-gray10 placeholder:text-gray20 font-['Satoshi Variable'] max-md:font-normal max-md:text-sm
                 text-black max-md:h-11 max-lg:h-[54px] max-lg:font-medium max-lg:text-lg ${isDisabled ? 'opacity-40' : ''}`}
                placeholder="Search"
                disabled={isDisabled}
                onChange={searchOnchange}
                value={searchInput}
            />
            <img className="w-6 h-6 absolute right-3 max-md:top-[11px] max-lg:top-[16px]" src={searchIcon} alt="searchicon" />
        </div>
        {isNext ? (
            <span onClick={() => closeOnClick()}>
                <img src={blueCancelIcon} alt="blueCancelIcon" className="cursor-pointer  max-md:w-[38px] max-md:h-[38px] max-lg:w-[50px] max-lg:h-[50px]" />
            </span>
        ) : (
            <span onClick={() => addNewOnClick()}>
                <img src={bluePlusCircleIcon} alt="bluePlusCircleIcon" className="cursor-pointer  max-md:w-[38px] max-md:h-[38px] max-lg:w-[50px] max-lg:h-[50px]" />
            </span>
        )}
    </div>
);

export default ResponsiveHeaderContentBlock;
