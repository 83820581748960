import React from 'react';

const IconWithButton = ({ title, icon, isColor = false, onclick, isDisabled = false, isMoreClass = '' }: any) => (
    <button
        className={`px-4 max-md:px-3 py-2 rounded-full justify-center me-1 items-center gap-1 flex ${
            isColor ? 'bg-black text-white border-black' : 'text-black border border-gray20'
        } ${isMoreClass} max-md:me-0 ${isDisabled ? 'opacity-40' : ''}`}
        onClick={onclick}
        disabled={isDisabled}
    >
        {icon && <img className="w-4 h-4 rounded-full" src={icon} alt="img" />}
        <div className="text-sm font-normal font-['Satoshi Variable'] whitespace-nowrap max-md:text-xs">{title}</div>
    </button>
);

export default IconWithButton;
