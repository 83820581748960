import React from 'react';

const ResponsiveTextWithIconButton = ({ title, icon, onclick, isDisabled = false, isDivMoreClass = '', isBtnMoreClass = '', isImgMoreClass = '', IsIcon = null, size = 24 }: any) => (
    <button
        className={`${isDisabled ? 'opacity-40' : ''} px-4 py-2 rounded-full justify-center items-center gap-2 flex cursor-pointer bg-secondary w-full ${isBtnMoreClass}`}
        onClick={onclick}
        disabled={isDisabled}
    >
        <div className={`text-white text-sm font-bold font-['Satoshi Variable'] uppercase whitespace-nowrap ${isDivMoreClass}`}>{title}</div>
        {icon && <img className={`w-6 h-6 rounded-full ${isImgMoreClass}`} src={icon} alt="img" />}
        {IsIcon && (
            <div className="text-white">
                <IsIcon size={size} />
            </div>
        )}
    </button>
);

export default ResponsiveTextWithIconButton;
