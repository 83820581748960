import React, { useEffect, useState } from 'react';
import { BsDot } from 'react-icons/bs';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MonthlyOverviewJobType = ({ chartOverViewJobTypeData, jobCompany }: any) => {
    const allFlaggingZero = chartOverViewJobTypeData?.flagging?.every((value: any) => value === 0);
    const allParkingZero = chartOverViewJobTypeData?.parking?.every((value: any) => value === 0);

    const options: any = {
        radius: 0,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                // text: "Chart.js Line Chart",
            },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'top',
                offset: -22,
                color: (context: any) => (context.dataset.label === 'Parking' ? 'black' : 'white'),
                formatter: (value: any) => (value === 0 ? '' : value),
            },
            tooltip: {
                enabled: false,
            },
        },
        scales: {
            // to remove the labels
            x: {
                ticks: {
                    // display: false,
                },

                // to remove the x-axis grid
                grid: {
                    // drawBorder: false,
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            // to remove the y-axis labels
            y: {
                ticks: {
                    display: false,
                    // beginAtZero: true,
                },
                // to remove the y-axis grid
                grid: {
                    drawBorder: true,
                    display: false,
                },
                border: {
                    display: false,
                },
            },
        },
    };

    const labels = chartOverViewJobTypeData?.months;

    const data = {
        labels,
        datasets: [
            {
                label: 'Parking',
                data: chartOverViewJobTypeData?.parking,
                backgroundColor: '#EFF0F1',
                borderRadius: 20,
            },
            {
                label: 'Flagging',
                data: chartOverViewJobTypeData?.flagging,
                backgroundColor: '#627BE8',
                borderRadius: 20,
            },
        ],
    };
    return (
        <div className="w-full p-4 bg-white rounded-2xl main-shadow my-4 max-md:my-4 max-lg:my-5">
            <div className="flex justify-between max-md:flex-col max-md:gap-[10px]">
                <div className="flex items-center max-md:justify-between">
                    <h2 className="text-black text-sm font-black">Monthly `Overview</h2>
                    <p className="text-black80 text-xs font-medium ms-1 mt-[2px]">Last 6 month</p>
                </div>
                <div className="flex items-center max-md:justify-between">
                    <div className="flex items-center">
                        <div>
                            <p className="text-zinc-400 text-xs font-normal flex items-center me-2 max-md:-ms-[0.8rem]">
                                <BsDot className="h-8 w-8" />
                                <span className="-ms-2">Parking Hrs</span>
                            </p>
                        </div>
                        <div>
                            <p className="text-zinc-400 text-xs font-normal flex items-center">
                                <BsDot className="h-8 w-8 text-blue-700" />
                                <span className="-ms-2">Flagging Hrs</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center px-[6px] py-1 bg-zinc-100 rounded-lg border border-gray10 ms-5">
                        <HiOutlineDotsHorizontal className=" w-5 h-5" />
                    </div>
                </div>
            </div>
            <div className="w-full monthly-chart h-72">
                {allFlaggingZero && allParkingZero ? <div className="emptyMessageShow h-72">Data Not Found</div> : <Bar options={options} data={data} plugins={[ChartDataLabels]} />}
            </div>
        </div>
    );
};

export default MonthlyOverviewJobType;
