import React from 'react';
import westImg from 'src/assets/img/westImg.png';
import fsImg from 'src/assets/img/fsImg.png';
import logImg from 'src/assets/img/logImg.png';
import { TiFlash } from 'react-icons/ti';
import { FaCircleExclamation } from 'react-icons/fa6';
import upDirectionAerrow from 'src/assets/svgs/upDirectionAerrow.svg';
import downDirectionAerrow from 'src/assets/svgs/downDirectionAerrow.svg';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { Tooltip } from 'flowbite-react';
import { breakPoints } from 'src/constants/general';
import ResponsiveTextWithIconButton from '../../ResponsiveTextWithIconButton';

const ResponsiveJobListAccordion = ({ jobListData, openAcoordionResponsive, setOpenAcoordionResponsive, employeeResponsive, GetStartDate, GetEndDate, Getstatus, onRowClick }: any) => {
    const { isMobile }: any = useDeviceDetect();
    return (
        <>
            {jobListData?.length !== 0 &&
                jobListData?.map((data: any, index: number) => (
                    <div key={index} className={`${index === 0 ? '' : 'mt-3'}`}>
                        <div className={`w-full flex p-[10px] bg-white flex-col rounded-3xl ${openAcoordionResponsive === index ? '' : 'border-b-2 border-[#C1C1C1]'}`}>
                            <button className={`flex items-center justify-between w-full`} onClick={() => setOpenAcoordionResponsive((preavState: any) => (preavState === index ? null : index))}>
                                <div className="flex items-center gap-2">
                                    <img
                                        className="max-md:w-6 max-md:h-6 max-lg:w-[26px] max-lg:h-[26px] rounded-full"
                                        src={data.companyName === 'West' ? westImg : data.companyName === 'Log' ? logImg : fsImg}
                                        alt="img"
                                    />
                                    <div className="text-black max-md:text-xs font-bold font-['Satoshi Variable'] uppercase max-lg:text-sm">{data.companyName}</div>
                                </div>
                                <div className="flex items-center gap-2 ">
                                    {data.isAsap ? (
                                        <span className="w-5 h-5 rounded-full bg-red-50 text-red500 flex justify-center items-center">
                                            <TiFlash size={18} />
                                        </span>
                                    ) : (
                                        <span className="w-5 h-5 rounded-full flex justify-center items-center"></span>
                                    )}
                                    {data.isMandatory ? (
                                        <span className={`w-5 h-5 rounded-full bg-white text-blue-800 flex justify-center items-center`}>
                                            <FaCircleExclamation size={18} />
                                        </span>
                                    ) : (
                                        <span className="w-5 h-5 rounded-full flex justify-center items-center"></span>
                                    )}
                                </div>
                                <div className="max-md:flex flex-col font-['Satoshi Variable'] gap-1 max-lg:hidden">
                                    <div className="flex">
                                        <span className="text-[#627BE8] font-bold text-xs">Job Type :</span>
                                        <span className="font-medium text-xs ml-2">{data?.jobType}</span>
                                    </div>
                                    <div className="flex">
                                        <span className="text-[#627BE8] font-bold text-xs">Job ID :</span>
                                        <span className="font-medium text-xs ml-2">{data?.jobConfirmation}</span>
                                    </div>
                                </div>
                                <div className="max-lg:flex max-md:hidden">
                                    <span className="text-[#627BE8] font-bold text-xs">Job Type :</span>
                                    <span className="font-medium text-xs ml-2">{data?.jobType}</span>
                                </div>
                                <div className="max-lg:flex max-md:hidden">
                                    <span className="text-[#627BE8] font-bold text-xs">Job ID :</span>
                                    <span className="font-medium text-xs ml-2">{data?.jobConfirmation}</span>
                                </div>
                                <div>
                                    <span className={``}>
                                        <img className="w-[30px] h-[30px]" src={`${openAcoordionResponsive === index ? upDirectionAerrow : downDirectionAerrow}`} alt="img" />
                                    </span>
                                </div>
                            </button>
                            {isMobile < breakPoints.mobile ? (
                                <div className={`${openAcoordionResponsive === index ? 'block' : 'hidden'} mt-3 border-t-2 border-[#C1C1C1] p-[10px]`}>
                                    <div className="mb-2 flex items-center">
                                        <span className="w-[131px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Assigned Employee</span>
                                        <span className="mr-[10px] ml-[20px] text-[#627BE8]">:</span>
                                        {data?.employeeList?.length === 1 ? (
                                            <p className="truncate text-xs font-medium">{`${data.employeeList[0].name} ${data.employeeList[0].surname}`}</p>
                                        ) : (
                                            <div className="flex items-center">
                                                <p className="truncate me-[6px] text-xs font-medium">{`${data.employeeList[0].name} ${data.employeeList[0].surname}..`}</p>
                                                <Tooltip className="bg-black text-white" content={<>{employeeResponsive(data)}</>}>
                                                    <div className="w-4 h-4 px-2 pt-0.5 pb-1 bg-indigo-500 rounded-2xl flex-col justify-center items-center gap-2 inline-flex">
                                                        <div className="text-white text-xs font-medium font-['Satoshi Variable']">{data?.employeeList?.length}</div>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-2 flex items-center">
                                        <span className="w-[131px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Location</span>
                                        <span className="mr-[10px] ml-[20px] text-[#627BE8]">:</span>
                                        <span className="font-['Satoshi Variable'] font-medium text-xs">{data?.location}</span>
                                    </div>
                                    <div className="mb-2 flex items-center">
                                        <span className="w-[131px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Start Date</span>
                                        <span className="mr-[10px] ml-[20px] text-[#627BE8]">:</span>
                                        <span className="font-['Satoshi Variable'] font-medium text-xs">{GetStartDate(data)}</span>
                                    </div>
                                    <div className="mb-2 flex items-center">
                                        <span className="w-[131px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">End Date</span>
                                        <span className="mr-[10px] ml-[20px] text-[#627BE8]">:</span>
                                        <span className="font-['Satoshi Variable'] font-medium text-xs">{GetEndDate(data)}</span>
                                    </div>
                                    <div className="mb-2 flex items-center">
                                        <span className="w-[131px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Department</span>
                                        <span className="mr-[10px] ml-[20px] text-[#627BE8]">:</span>
                                        <span className="font-['Satoshi Variable'] font-medium text-xs">{data?.departmentName}</span>
                                    </div>
                                    <div className="mb-2 flex items-center">
                                        <span className="w-[131px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Status</span>
                                        <span className="mr-[10px] ml-[20px] text-[#627BE8]">:</span>
                                        <span className="font-['Satoshi Variable'] font-medium text-xs">{Getstatus(data)}</span>
                                    </div>
                                    <div className="mt-4">
                                        <ResponsiveTextWithIconButton title={'Get More Detail'} onclick={() => onRowClick({ data })} />
                                    </div>
                                </div>
                            ) : (
                                <div className={`${openAcoordionResponsive === index ? 'block' : 'hidden'}`}>
                                    <div
                                        className={`${
                                            openAcoordionResponsive === index ? 'flex' : 'hidden'
                                        } mt-3 border-t-2 border-[#C1C1C1] pt-[10px] pb-[10px] pr-[15px] pl-[15px] justify-between items-center`}
                                    >
                                        <div className="">
                                            <div className="mb-4 flex items-center">
                                                <span className="w-[131px] font-['Satoshi Variable'] font-bold text-sm text-[#627BE8]">Assigned Employee</span>
                                                <div className="flex items-center">
                                                    <span className="mx-[10px] text-[#627BE8]">:</span>
                                                    {data?.employeeList?.length === 1 ? (
                                                        <p className="truncate">{`${data.employeeList[0].name} ${data.employeeList[0].surname}`}</p>
                                                    ) : (
                                                        <div className="flex">
                                                            <p className="truncate me-[6px]">{`${data.employeeList[0].name} ${data.employeeList[0].surname}..`}</p>
                                                            <Tooltip className="bg-black text-white" content={<>{employeeResponsive(data)}</>}>
                                                                <div className="w-4 h-4 px-2 pt-0.5 pb-1 bg-indigo-500 rounded-2xl flex-col justify-center items-center gap-2 inline-flex">
                                                                    <div className="text-white text-xs font-medium font-['Satoshi Variable']">{data?.employeeList?.length}</div>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mb-4 flex items-center">
                                                <span className="w-[131px] font-['Satoshi Variable'] font-bold text-sm text-[#627BE8]">Start Date</span>
                                                <div className="">
                                                    <span className="mx-[10px] text-[#627BE8]">:</span>
                                                    <span className="font-['Satoshi Variable'] font-medium text-sm">{GetStartDate(data)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="mb-4 flex items-center">
                                                <span className="w-[62px] font-['Satoshi Variable'] font-bold text-sm text-[#627BE8]">Location</span>
                                                <div className="">
                                                    <span className="mx-[10px] text-[#627BE8]">:</span>
                                                    <span className="font-['Satoshi Variable'] font-medium text-sm">{data?.location}</span>
                                                </div>
                                            </div>
                                            <div className="mb-4 flex items-center">
                                                <span className="w-[62px] font-['Satoshi Variable'] font-bold text-sm text-[#627BE8]">End Date</span>
                                                <div className="flex items-center">
                                                    <span className="mx-[10px] text-[#627BE8]">:</span>
                                                    <span className="font-['Satoshi Variable'] font-medium text-sm">{GetEndDate(data)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="mb-4 flex items-center">
                                                <span className="w-[82px] font-['Satoshi Variable'] font-bold text-sm text-[#627BE8]">Department</span>
                                                <div className="">
                                                    <span className="mx-[10px] text-[#627BE8]">:</span>
                                                    <span className="font-['Satoshi Variable'] font-medium text-sm">{data?.departmentName}</span>
                                                </div>
                                            </div>
                                            <div className="mb-4 flex items-center">
                                                <span className="w-[82px] font-['Satoshi Variable'] font-bold text-sm text-[#627BE8]">Status</span>
                                                <div className="flex items-center">
                                                    <span className="mx-[10px] text-[#627BE8]">:</span>
                                                    <span className="font-['Satoshi Variable'] font-medium text-sm">{Getstatus(data)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <ResponsiveTextWithIconButton title={'Get More Detail'} onclick={() => onRowClick({ data })} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
        </>
    );
};
export default ResponsiveJobListAccordion;
