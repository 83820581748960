import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import InputBox from 'src/components/Common/InputBox/InputBox';
import * as Yup from 'yup';
import { validation } from 'src/constants/general';
import { toaster } from 'src/utils/toaster';
import { createAdmin } from 'src/services/admin';
import ResponsiveTextWithIconButton from 'src/components/Responsive/ResponsiveTextWithIconButton';
import rightIcon from 'src/assets/svgs/rightIcon.svg';

const AddNewAdmin = ({ setIsSave, isSave, isOnSuccess, setBtnDisabled, btnDisabled }: any) => {
    const [error, setError] = useState('');
    const [isSaveValidation, setIsSaveValidation] = useState(false);
    useEffect(() => {
        if (isSave) {
            formik.handleSubmit();
            setIsSave(false);
            setIsSaveValidation(true);
        }
    }, [isSave]);

    const onSubmit = async (values: any, helpers: any) => {
        setBtnDisabled(true);
        const payload = {
            name: `${values.name} ${values.surName}`,
            email: values.email,
            password: values.password,
        };
        try {
            const res = await createAdmin(payload);
            if (res.status === 200) {
                isOnSuccess();
                toaster('success', res.data.message);
            } else {
                setError(res.data.message);
                setBtnDisabled(false);
            }
        } catch (err: any) {
            setError(err?.response.message);
        }
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            surName: '',
            password: '',
            passwordConfirmation: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter name'),
            email: Yup.string().required('Please enter email').email('Please enter proper email'),
            surName: Yup.string().required('Please enter surname'),
            password: Yup.string().required('Please enter password').min(6, 'Password required minimum 6 characters'),
            passwordConfirmation: Yup.string()
                .required('Please enter confirm password')
                .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
        }),
        onSubmit: onSubmit,
    });

    return (
        <>
            <div className="p-8 mt-6 bg-white rounded-3xl">
                <div>
                    <div>
                        <p className="text-black text-base font-black">Adding new Admin</p>
                        <p className="text-black text-sm font-medium leading-none mt-2">all the information bellow is required to add new admin</p>
                    </div>
                    <form className="mt-4 gap-5">
                        <div className="flex flex-row gap-5 max-lg:flex-col">
                            <div className="flex flex-col max-lg:flex-row gap-5">
                                <div className="flex flex-col md:flex-row gap-5 max-lg:w-full">
                                    <div className="md:w-1/2 lg:w-[230px] w-full">
                                        <InputBox
                                            name={'name'}
                                            type={'text'}
                                            className="bg-gray10 border-none"
                                            onChange={(e: any) => {
                                                formik.handleChange(e);
                                                setError('');
                                                setIsSaveValidation(false);
                                            }}
                                            // onBlur={formik.handleBlur}
                                            inputId={'floating_filled_admin_1'}
                                            value={formik.values.name}
                                            placeholder="Enter First Name"
                                            error={formik.errors.name && formik.touched.name && isSaveValidation ? formik.errors.name : ''}
                                            maxLength={validation.name}
                                        />
                                    </div>
                                    <div className="md:w-1/2 lg:w-[230px] w-full">
                                        <InputBox
                                            name={'surName'}
                                            type={'text'}
                                            className="bg-gray10 border-none"
                                            onChange={(e: any) => {
                                                formik.handleChange(e);
                                                setError('');
                                                setIsSaveValidation(false);
                                            }}
                                            // onBlur={formik.handleBlur}
                                            inputId={'floating_filled_admin_4'}
                                            value={formik.values.surName}
                                            placeholder="Enter Last Name"
                                            error={formik.errors.surName && formik.touched.surName && isSaveValidation ? formik.errors.surName : ''}
                                            maxLength={validation.surname}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row gap-5">
                                <div className=" max-lg:w-full lg:w-[230px]">
                                    <InputBox
                                        name={'email'}
                                        type={'email'}
                                        className="bg-gray10 border-none"
                                        onChange={(e: any) => {
                                            formik.handleChange(e);
                                            setError('');
                                            setIsSaveValidation(false);
                                        }}
                                        // onBlur={formik.handleBlur}
                                        inputId={'floating_filled_admin_2'}
                                        value={formik.values.email}
                                        placeholder="Enter Email"
                                        error={formik.errors.email && formik.touched.email && isSaveValidation ? formik.errors.email : ''}
                                        maxLength={validation.email}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-5 mt-5 ">
                            <div className="  md:w-1/2 lg:w-[230px] w-full">
                                <InputBox
                                    name={'password'}
                                    type={'password'}
                                    className="bg-gray10 border-none"
                                    onChange={(e: any) => {
                                        formik.handleChange(e);
                                        setError('');
                                        setIsSaveValidation(false);
                                    }}
                                    // onBlur={formik.handleBlur}
                                    inputId={'floating_filled_admin_5'}
                                    value={formik.values.password}
                                    placeholder="Enter Password"
                                    error={formik.errors.password && formik.touched.password && isSaveValidation ? formik.errors.password : ''}
                                    maxLength={validation.password}
                                />
                            </div>
                            <div className=" md:w-1/2 lg:w-[230px]  w-full">
                                <InputBox
                                    name={'passwordConfirmation'}
                                    type={'password'}
                                    className="bg-gray10 border-none"
                                    onChange={(e: any) => {
                                        formik.handleChange(e);
                                        setError('');
                                        setIsSaveValidation(false);
                                    }}
                                    // onBlur={formik.handleBlur}
                                    inputId={'floating_filled_admin_3'}
                                    value={formik.values.passwordConfirmation}
                                    placeholder="Enter  Confirm Password"
                                    error={formik.errors.passwordConfirmation && formik.touched.passwordConfirmation && isSaveValidation ? formik.errors.passwordConfirmation : ''}
                                    maxLength={validation.email}
                                />
                            </div>
                        </div>
                        {error && <p className="text-red-600 mt-10 text-sm text-center">{error}</p>}
                    </form>
                </div>
            </div>
            <div className="hidden max-lg:block  pb-3 max-md:pt-5 max-lg:pt-[26px]">
                <ResponsiveTextWithIconButton title={'SAVE'} icon={rightIcon} isDisabled={btnDisabled} onclick={() => setIsSave(true)} />
            </div>
        </>
    );
};
export default AddNewAdmin;
