import React from 'react';

const ConfirmationPopUp = ({ submitOnClick, cancelOnClick, confirmText }: any) => (
    <>
        <div>
            <p className="mt-5 mb-8 text-sm font-medium text-center">{confirmText}</p>
            <div className="flex gap-2">
                <button
                    type="button"
                    className="text-white w-1/2 bg-secondary hover:bg-secondary80 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    onClick={() => submitOnClick()}
                >
                    Yes
                </button>
                <button
                    type="button"
                    className="text-gray-500 w-1/2 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                    onClick={() => cancelOnClick()}
                >
                    No
                </button>
            </div>
        </div>
    </>
);

export default ConfirmationPopUp;
