import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import IconWithButton from 'src/components/Common/IconWithButton';
import InputBox from 'src/components/Common/InputBox/InputBox';
import PhoneInputs from 'src/components/Common/PhoneInputs';
import { createEmployee } from 'src/services/employee';
import * as Yup from 'yup';
import westImg from 'src/assets/img/westImg.png';
import logImg from 'src/assets/img/logImg.png';
import fsImg from 'src/assets/img/fsImg.png';
import { companyName, general, validation } from 'src/constants/general';
import { toaster } from 'src/utils/toaster';
import ResponsiveTextWithIconButton from 'src/components/Responsive/ResponsiveTextWithIconButton';
import rightIcon from 'src/assets/svgs/rightIcon.svg';
interface PhoneNumber {
    countryCode: string;
    num: string;
    countryName: string;
}

const AddNewEmployee = ({ setIsSave, isSave, listData, isOnSuccess, setBtnDisabled, btnDisabled }: any) => {
    const [error, setError] = useState('');
    const [company, setCompany] = useState({
        name: listData?.companyList[0]?.name,
        id: listData?.companyList[0]?.id,
    });
    const [number, setNumber] = useState<PhoneNumber>({
        countryCode: '',
        num: '',
        countryName: '',
    });
    useEffect(() => {
        if (isSave) {
            formik.handleSubmit();
            setIsSave(false);
        }
    }, [isSave]);

    useEffect(() => {
        setCompany({
            name: listData?.companyList[0]?.name,
            id: listData?.companyList[0]?.id,
        });
        formik.setFieldValue('jobType', listData?.jobList[0]?.id);
    }, [listData]);

    const onSubmit = async (values: any, helpers: any) => {
        setBtnDisabled(true);
        const payload = {
            name: values.name,
            surname: values.surName,
            email: values.email,
            phoneNumber: number.num,
            languageFluency: values.language_fluency,
            backupEmail: values.backupEmail,
            companyId: company.id,
            jobTypeId: values.jobType,
            countryCode: number.countryCode,
            countryName: number.countryName,
        };
        try {
            const res = await createEmployee(payload);
            if (res.status === 200) {
                isOnSuccess();
                toaster('success', res.data.message);
            } else {
                setError(res.data.message);
                setBtnDisabled(false);
            }
        } catch (err: any) {
            setError(err?.response.message);
        }
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phoneNumber: '',
            surName: '',
            backupEmail: '',
            language_fluency: general.BEGINNER,
            jobType: listData?.jobList[0]?.id,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter name'),
            // email: Yup.string().required('Please enter email').email('Please enter proper email'),
            email: Yup.string().email('Please enter proper email'),
            // phoneNumber: Yup.string().required('Please enter phone number'),
            surName: Yup.string().required('Please enter surname'),
            // backupEmail: Yup.string().required('Please enter backup email').email('Please enter proper backup email'),
            backupEmail: Yup.string().email('Please enter proper backup email'),
            // language_fluency: Yup.string().required('Please choose languagefluency'),
        }),
        onSubmit: onSubmit,
    });

    return (
        <div className="relative flex-1">
            <div className="p-4  md:p-8 mt-6 bg-white rounded-3xl relative w-full flex-1">
                <div className="relative w-full">
                    <div>
                        <p className="text-black text-base font-black">Adding new Employee</p>
                        <p className="text-black text-sm font-medium leading-none mt-2">all the information bellow is required to add new employee</p>
                    </div>
                    <div className="mt-4 flex flex-col lg:flex-row lg:gap-[50px] md:gap-5 gap-3">
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-col md:flex-row lg:gap-10 md:gap-5 gap-3">
                                <div className="w-full md:w-1/2">
                                    <InputBox
                                        name={'name'}
                                        type={'text'}
                                        className="bg-gray10 border-none"
                                        onChange={(e: any) => {
                                            formik.handleChange(e);
                                            setError('');
                                        }}
                                        onBlur={formik.handleBlur}
                                        inputId={'floating_filled_2'}
                                        value={formik.values.name}
                                        placeholder="Enter The Name"
                                        error={formik.errors.name && formik.touched.name ? formik.errors.name : ''}
                                        maxLength={validation.name}
                                    />
                                </div>
                                <div className="w-full md:w-1/2">
                                    <InputBox
                                        name={'surName'}
                                        type={'text'}
                                        className="bg-gray10 border-none"
                                        onChange={(e: any) => {
                                            formik.handleChange(e);
                                            setError('');
                                        }}
                                        onBlur={formik.handleBlur}
                                        inputId={'floating_filled_4'}
                                        value={formik.values.surName}
                                        placeholder="Enter The Surname"
                                        error={formik.errors.surName && formik.touched.surName ? formik.errors.surName : ''}
                                        maxLength={validation.surname}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row lg:gap-10 md:gap-5 gap-3">
                                <div className="w-full md:w-1/2">
                                    <InputBox
                                        name={'email'}
                                        type={'email'}
                                        className="bg-gray10 border-none"
                                        onChange={(e: any) => {
                                            formik.handleChange(e);
                                            setError('');
                                        }}
                                        onBlur={formik.handleBlur}
                                        inputId={'floating_filled_3'}
                                        value={formik.values.email}
                                        placeholder="Enter The Email"
                                        error={formik.errors.email && formik.touched.email ? formik.errors.email : ''}
                                        maxLength={validation.email}
                                    />
                                </div>
                                <div className="w-full md:w-1/2">
                                    <InputBox
                                        name={'backupEmail'}
                                        type={'email'}
                                        className="bg-gray10 border-none"
                                        onChange={(e: any) => {
                                            formik.handleChange(e);
                                            setError('');
                                        }}
                                        onBlur={formik.handleBlur}
                                        inputId={'floating_filled_5'}
                                        value={formik.values.backupEmail}
                                        placeholder="Backup Email Goes here"
                                        error={formik.errors.backupEmail && formik.touched.backupEmail ? formik.errors.backupEmail : ''}
                                        maxLength={validation.email}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col lg:gap-3 md:gap-5 gap-3 ">
                            <div className="flex flex-col md:flex-row lg:gap-10 md:gap-5 gap-3">
                                <div className="w-full lg:w-1/2 md:w-[49%]">
                                    <PhoneInputs
                                        formik={formik}
                                        inputClass="max-w-full"
                                        onChange={() => {
                                            setError('');
                                        }}
                                        setNumber={setNumber}
                                        countryName={number.countryName}
                                    />
                                </div>
                                <div className="flex gap-2 md:justify-center items-center">
                                    {listData.companyList?.map((data: any, index: number) => (
                                        <div className="md:w-[50%]" key={index}>
                                            <IconWithButton
                                                title={data?.name}
                                                icon={data?.name === companyName.West ? westImg : data?.name === companyName.Log ? logImg : fsImg}
                                                isColor={data?.name === company.name ? true : false}
                                                onclick={() => {
                                                    setCompany({
                                                        name: data?.name,
                                                        id: data?.id,
                                                    });
                                                    setError('');
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row lg:gap-10 md:gap-5 gap-3">
                                <div className="w-full md:w-[50%]">
                                    <p className="text-gray-500 text-xs font-normal">Language Fluency</p>
                                    <div className="mt-2 flex gap-4 language_radio">
                                        <div>
                                            <input
                                                type="radio"
                                                name="language_fluency"
                                                value={general.BEGINNER}
                                                id={general.BEGINNER}
                                                checked={formik.values.language_fluency === general.BEGINNER}
                                                onChange={() => {
                                                    formik.setFieldValue('language_fluency', general.BEGINNER);
                                                    setError('');
                                                }}
                                                className="mr-2"
                                            />
                                            <label className="font-bold text-xs" htmlFor={general.BEGINNER}>
                                                Beginner
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                name="language_fluency"
                                                value={general.INTERMEDIATE}
                                                id={general.INTERMEDIATE}
                                                checked={formik.values.language_fluency === general.INTERMEDIATE}
                                                onChange={() => {
                                                    formik.setFieldValue('language_fluency', general.INTERMEDIATE);
                                                    setError('');
                                                }}
                                                className="mr-2"
                                            />
                                            <label className="font-bold text-xs" htmlFor={general.INTERMEDIATE}>
                                                Intermediate
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                name="language_fluency"
                                                value={general.FLUENT}
                                                id={general.FLUENT}
                                                checked={formik.values.language_fluency === general.FLUENT}
                                                onChange={() => {
                                                    formik.setFieldValue('language_fluency', general.FLUENT);
                                                    setError('');
                                                }}
                                                className="mr-2"
                                            />
                                            <label className="font-bold text-xs" htmlFor={general.FLUENT}>
                                                Fluent
                                            </label>
                                        </div>
                                    </div>
                                    {formik.errors.language_fluency && formik.touched.language_fluency && <p className="text-red-600 mt-1 text-sm">{formik.errors.language_fluency}</p>}
                                </div>
                                <div className="ml-0">
                                    <p className="text-gray-500 text-xs font-normal">Job type</p>
                                    <div className="mt-2 flex gap-4 language_radio">
                                        {listData.jobList?.map((data: any, index: number) => (
                                            <div key={index}>
                                                <input
                                                    type="radio"
                                                    name="jobType"
                                                    value={data.id}
                                                    id={data.name}
                                                    checked={formik.values.jobType === data.id}
                                                    onChange={() => {
                                                        formik.setFieldValue('jobType', data.id);
                                                        setError('');
                                                    }}
                                                    className="mr-2"
                                                />
                                                <label className="font-bold text-xs" htmlFor={data.name}>
                                                    {data.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {error && <p className="text-red-600 mt-10 text-sm text-center">{error}</p>}
                    </div>
                </div>
            </div>
            <div className="hidden max-lg:block  pb-3 max-md:pt-5 max-lg:pt-[26px]">
                <ResponsiveTextWithIconButton title={'SAVE'} icon={rightIcon} isDisabled={btnDisabled} onclick={() => setIsSave(true)} />
            </div>
        </div>
    );
};
export default AddNewEmployee;
