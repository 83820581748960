import React from 'react';
import IconWithButton from 'src/components/Common/IconWithButton';
import employeeSortImg from 'src/assets/img/employeeSortByimg.png';
import { companyName, general } from 'src/constants/general';
import { BsDot } from 'react-icons/bs';
import SelectBox from 'src/components/Common/SelectBox/SelectBox';
import westImg from 'src/assets/img/westImg.png';
import logImg from 'src/assets/img/logImg.png';
import fsImg from 'src/assets/img/fsImg.png';

const EmployeeSortFilter = ({ setSelectedValue, selectedValue, listData, setSortBy, sortBy }: any) => {
    const options = [
        {
            value: '',
            label: 'All',
        },
        {
            value: general.AVAILABLE,
            label: 'Available',
        },
        {
            value: general.WORKING,
            label: 'Working',
        },
        {
            value: general.UNAVAILABLE,
            label: 'Unavailable',
        },
        {
            value: general.ON_HOLD,
            label: 'On Hold',
        },
        { value: general.BUSY, label: 'Busy' },
        {
            value: general.FIRED,
            label: 'Fired',
        },
        {
            value: general.QUIT,
            label: 'Quit',
        },
    ];

    const selectFunction = (data: any) => {
        setSelectedValue(data);
    };
    const GetCurrentstatus = (label: any) => {
        const statusColors: any = {
            Working: '#36B845',
            Available: '#EF3434',
            Unavailable: '#7A3CE3',
            'On Hold': '#627BE8',
            Busy: '#FFCC15',
            Fired: '#CC5500',
            Quit: 'pink',
        };
        const isColor = statusColors[label] || '';
        return isColor;
    };
    const formatOptionLabel = ({ valued, label, customAbbreviation }: any) => (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        marginLeft: '-5px',
                        marginTop: '2px',
                        width: '25px',
                    }}
                >
                    <BsDot size={28} style={{ color: GetCurrentstatus(label) }} />
                </div>
                <div>{label}</div>
            </div>
        </>
    );
    return (
        <div className="mt-4 px-5 md:px-5 h-[calc(100vh-270px)] overflow-y-auto scrollbar-hide">
            <h4 className="text-black text-base font-medium">Sort By</h4>
            <div className="mt-3">
                <p className="text-xs mb-2">Date</p>
                <div className="flex gap-2">
                    <IconWithButton
                        title={'Newest'}
                        isColor={sortBy.date.name === 'Newest' ? true : false}
                        icon={employeeSortImg}
                        onclick={() =>
                            setSortBy((prevData: any) => ({
                                ...prevData,
                                date: {
                                    name: 'Newest',
                                    value: 'latest',
                                },
                            }))
                        }
                    />
                    <IconWithButton
                        title={'Oldest'}
                        isColor={sortBy.date.name === 'Oldest' ? true : false}
                        icon={employeeSortImg}
                        onclick={() =>
                            setSortBy((prevData: any) => ({
                                ...prevData,
                                date: {
                                    name: 'Oldest',
                                    value: 'oldest',
                                },
                            }))
                        }
                    />
                    <IconWithButton
                        title={'All'}
                        isColor={sortBy.date.name === 'All' ? true : false}
                        icon={employeeSortImg}
                        onclick={() =>
                            setSortBy((prevData: any) => ({
                                ...prevData,
                                date: {
                                    name: 'All',
                                    value: '',
                                },
                            }))
                        }
                    />
                </div>
            </div>
            <div className="mt-4">
                <p className="text-xs mb-2">Job Type</p>
                <div className="flex gap-2">
                    {listData.jobList?.map((data: any, index: number) => (
                        <div key={index}>
                            <IconWithButton
                                title={data.name}
                                isColor={sortBy.jobType.name === data.name ? true : false}
                                icon={employeeSortImg}
                                onclick={() =>
                                    setSortBy((prevData: any) => ({
                                        ...prevData,
                                        jobType: {
                                            name: data.name,
                                            id: data.id,
                                        },
                                    }))
                                }
                            />
                        </div>
                    ))}
                    <IconWithButton
                        title={'All'}
                        isColor={sortBy.jobType.name === 'All' ? true : false}
                        icon={employeeSortImg}
                        onclick={() =>
                            setSortBy((prevData: any) => ({
                                ...prevData,
                                jobType: {
                                    name: 'All',
                                    id: '',
                                },
                            }))
                        }
                    />
                </div>
            </div>
            <div className="mt-4">
                <p className="text-xs mb-2">Rating</p>
                <div className="flex gap-2">
                    <IconWithButton
                        title={'Highest'}
                        isColor={sortBy.rating.name === 'Highest' ? true : false}
                        icon={employeeSortImg}
                        onclick={() =>
                            setSortBy((prevData: any) => ({
                                ...prevData,
                                rating: {
                                    name: 'Highest',
                                    value: 'highest',
                                },
                            }))
                        }
                    />
                    <IconWithButton
                        title={'Lowest'}
                        isColor={sortBy.rating.name === 'Lowest' ? true : false}
                        icon={employeeSortImg}
                        onclick={() =>
                            setSortBy((prevData: any) => ({
                                ...prevData,
                                rating: {
                                    name: 'Lowest',
                                    value: 'lowest',
                                },
                            }))
                        }
                    />
                    <IconWithButton
                        title={'All'}
                        isColor={sortBy.rating.name === 'All' ? true : false}
                        icon={employeeSortImg}
                        onclick={() =>
                            setSortBy((prevData: any) => ({
                                ...prevData,
                                rating: {
                                    name: 'All',
                                    value: '',
                                },
                            }))
                        }
                    />
                </div>
            </div>
            <div className="mt-4">
                <p className="text-xs mb-2">Company</p>
                <div className="flex gap-2">
                    {listData.companyList?.map((data: any, index: number) => (
                        <div key={index}>
                            <IconWithButton
                                title={data?.name}
                                icon={data?.name === companyName.West ? westImg : data?.name === companyName.Log ? logImg : fsImg}
                                isColor={sortBy.company.name === data.name ? true : false}
                                onclick={() =>
                                    setSortBy((prevData: any) => ({
                                        ...prevData,
                                        company: {
                                            name: data.name,
                                            id: data.id,
                                        },
                                    }))
                                }
                            />
                        </div>
                    ))}
                    {listData.companyList.length !== 0 && (
                        <IconWithButton
                            title={'All'}
                            isColor={sortBy.company.name === 'All' ? true : false}
                            icon={employeeSortImg}
                            onclick={() =>
                                setSortBy((prevData: any) => ({
                                    ...prevData,
                                    company: {
                                        name: 'All',
                                        id: '',
                                    },
                                }))
                            }
                        />
                    )}
                </div>
            </div>
            <div className="mt-6">
                <h4 className="text-black text-base font-medium">Filter by</h4>
                <div className="mt-3">
                    <div className="relative job_dropdown">
                        <SelectBox value={selectedValue} onChangeFunc={selectFunction} options={options} formatOptionLabel={formatOptionLabel} />
                        <label htmlFor="selectBox" className="absolute top-1 left-[14px] text-xs text-zinc-500 font-normal font-['Satoshi Variable']">
                            Status
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeSortFilter;
