import React from 'react';
import { ClipLoader } from 'react-spinners';

const Loader = ({ color = '#000000', size = 35, loading = false }: any) => (
    <>
        <ClipLoader color={color} size={size} loading={loading} />
    </>
);

export default Loader;
