import moment from 'moment';
import React from 'react';
import closeIcon from 'src/assets/img/closeImg.png';
import flagImg from 'src/assets/img/flagImg.png';
import backButtonImg from 'src/assets/img/employeeBackBtnImg.png';

const History = ({ setHistoryBtn, historyList, isBackHistory = () => {} }: any) => {
    const checkDate = (dateTimeStr: string) => {
        const dateTimeString = dateTimeStr;
        const dateTime = moment(dateTimeString);
        const minutesDifference = moment().diff(dateTime, 'minutes');
        const formattedTime = dateTime.format('h:mm A');
        const formattedDate = dateTime.format('DD.MM.YYYY');
        if (minutesDifference <= 2) {
            return 'Current';
        } else if (moment().isSame(dateTime, 'day')) {
            return formattedTime;
        } else {
            return `${formattedTime}-${formattedDate}`;
        }
    };
    return (
        <div>
            <div className="flex items-center gap-3">
                <img
                    src={closeIcon}
                    alt="closeicon"
                    className="h-6 w-6 cursor-pointer max-lg:hidden block"
                    onClick={() => {
                        setHistoryBtn(false);
                        isBackHistory();
                    }}
                />
                <img
                    src={backButtonImg}
                    alt="backaerrowimg"
                    className="h-6 w-6 cursor-pointer max-lg:block hidden"
                    onClick={() => {
                        setHistoryBtn(false);
                        isBackHistory();
                    }}
                />
                <p className="text-zinc-800 text-xs font-black font-['Satoshi Variable']">History</p>
            </div>
            <div className="max-h-[650px] overflow-y-auto job_history">
                {historyList.length === 0 ? (
                    <div className="mt-4">
                        <div className="flex mb-1">
                            <p className="text-zinc-800 text-xs font-bold font-['Satoshi Variable']">History Not Found</p>
                        </div>
                    </div>
                ) : (
                    historyList.map((data: any, index: number) => (
                        <div className="" key={index}>
                            <div className="mt-4 text-end me-6 max-lg:hidden ">
                                <p className="text-gray80 text-[10px] font-medium font-['Satoshi Variable'] mb-1">{checkDate(data.activityTime)}</p>
                                <div className="flex justify-end mb-1">
                                    <img className="w-4 h-4" src={flagImg} alt="flagimg" />
                                    <p className="text-zinc-800 text-xs font-bold font-['Satoshi Variable'] ms-1">{data.userId.email}</p>
                                </div>
                                <div className="text-zinc-500 text-xs font-normal font-['Satoshi Variable'] mb-1">{data.activity}</div>
                            </div>
                            <div className="border-b border-zinc-300 pt-[30px] pb-[15px] me-3 lg:hidden">
                                <div className="flex justify-start mb-1">
                                    <img className="w-4 h-4" src={flagImg} alt="flagimg" />
                                    <p className="text-zinc-800 text-xs font-bold font-['Satoshi Variable'] ms-1">{data.userId.email}</p>
                                </div>
                                <div className="flex justify-between">
                                    <div className="text-zinc-500 text-xs font-normal font-['Satoshi Variable'] mb-1">{data.activity}</div>
                                    <p className="text-gray80 text-[10px] font-medium font-['Satoshi Variable'] mb-1">{checkDate(data.activityTime)}</p>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};
export default History;
