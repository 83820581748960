import React from 'react';
import { BsDot } from 'react-icons/bs';

const RecivedCompleted = ({ title, value, isColor, icon, arrayType, jobTotal }: any) => (
    <div className={`p-5 rounded-2xl w-full h-full border border-gray10 main-shadow ${isColor ? 'bg-black text-white' : 'bg-white text-black80'}`}>
        <div className="flex justify-normal items-center gap-2 mb-5">
            <div className="w-8 h-8 p-2 bg-secondary20 rounded-xl justify-center items-center inline-flex">{icon}</div>
            <h2 className="text-base font-medium">{title}</h2>
        </div>
        <p className="text-center text-[32px] font-semibold">{value}</p>
        <ul className={`mt-5 text-sm font-light ${isColor ? 'text-white' : 'text-black80'}`}>
            {arrayType?.map((data: any, index: number) => (
                <li className="flex justify-between mb-2 last:mb-0" key={index}>
                    <span className="flex items-center">
                        <BsDot />
                        {data.name}
                    </span>{' '}
                    <span className=" font-semibold">{data?.[jobTotal]}</span>
                </li>
            ))}
        </ul>
    </div>
);
export default RecivedCompleted;
