import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { companyList, departmentList, jobTypesList, locationList, requestorList } from 'src/services/additional';

interface CommonApiState {
    companyData: any;
    jobTypeData: any;
    locationData: any;
    departmentData: any;
    requestorData: any;
    loading: boolean;
    error: any;
}

const initialState: CommonApiState = {
    companyData: [],
    jobTypeData: [],
    locationData: [],
    departmentData: [],
    requestorData: [],
    loading: false,
    error: null,
};

export const companyDataAsync: any = createAsyncThunk('data/fetchCompanyData', async () => {
    try {
        const data = await companyList();
        return data;
    } catch (error: any) {
        throw new Error(error);
    }
});
export const jobTypeDataAsync: any = createAsyncThunk('data/fetchJobTypeData', async () => {
    try {
        const data = await jobTypesList();
        return data;
    } catch (error: any) {
        throw new Error(error);
    }
});
export const locationDataAsync: any = createAsyncThunk('data/fetchLocationData', async () => {
    try {
        const data = await locationList();
        return data;
    } catch (error: any) {
        throw new Error(error);
    }
});
export const departmentDataAsync: any = createAsyncThunk('data/fetchDepartmentData', async () => {
    try {
        const data = await departmentList();
        return data;
    } catch (error: any) {
        throw new Error(error);
    }
});
export const requestorDataAsync: any = createAsyncThunk('data/fetchRequestorData', async () => {
    try {
        const data = await requestorList();
        return data;
    } catch (error: any) {
        throw new Error(error);
    }
});

const getCommonApi = createSlice({
    name: 'getCommonApi',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(companyDataAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(companyDataAsync.fulfilled, (state: any, action) => {
                state.loading = false;
                state.companyData = action.payload.data.data;
            })
            .addCase(companyDataAsync.rejected, (state: any, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(jobTypeDataAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(jobTypeDataAsync.fulfilled, (state: any, action) => {
                state.loading = false;
                state.jobTypeData = action.payload.data.data;
            })
            .addCase(jobTypeDataAsync.rejected, (state: any, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(locationDataAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(locationDataAsync.fulfilled, (state: any, action) => {
                state.loading = false;
                state.locationData = action.payload.data.data;
            })
            .addCase(locationDataAsync.rejected, (state: any, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(departmentDataAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(departmentDataAsync.fulfilled, (state: any, action) => {
                state.loading = false;
                state.departmentData = action.payload.data.data;
            })
            .addCase(departmentDataAsync.rejected, (state: any, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(requestorDataAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(requestorDataAsync.fulfilled, (state: any, action) => {
                state.loading = false;
                state.requestorData = action.payload.data.data;
            })
            .addCase(requestorDataAsync.rejected, (state: any, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default getCommonApi.reducer;
