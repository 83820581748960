import axios from 'axios';
import { PATH } from 'src/constants/path';

const BaseApiUrl = process.env.REACT_APP_API_URL;

export const axiosInterceptor = axios.create({
    baseURL: BaseApiUrl,
});

axiosInterceptor.interceptors.request.use(
    (config: any) => {
        try {
            if (localStorage.getItem('token')) {
                config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
            }
            return config;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    (error: any) => Promise.reject(error),
);

axiosInterceptor.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
        if (error?.name === 'CanceledError') {
            return;
        }
        if (error.response.status === 401 || error.response.status === 498) {
            localStorage.removeItem('token');
            window.location.href = PATH.LOGIN;
            return;
        }
        const errorCatch = error.response && error.response.status >= 400 && error.response.status < 500;

        if (errorCatch) {
            return error.response;
        }
        return Promise.reject(error.response);
    },
);
