import React from 'react';
import ReactSelect from 'react-select';

import { ISelectBoxProps } from './SelectBox.interface';

const colourStyles = {
    control: (defaultStyles: any, state: any) => ({
        ...defaultStyles,
        border: '1px solid #DFE1E2',
        boxShadow: state.isFocused ? 0 : 0,
        padding: '0.15rem 0.5rem',
        color: '#1F293C',
        fontSize: '14px',
        borderRadius: '40px',
    }),
    menuList: (styles: any) => ({
        ...styles,
        borderRadius: '0',
    }),
    menu: (styles: any) => ({
        ...styles,
        padding: '10px 0',
        borderRadius: '16px',
        border: '1px',
        boxShadow: '0px 5.33333px 16px 0px rgba(146, 140, 151, 0.25);',
    }),
    clearIndicator: (styles: any) => ({
        ...styles,
        borderRadius: '16px',
        padding: '1px',
        display: 'none',
        marginRight: '5px',
        color: 'white',
        backgroundColor: '#98A7C3',
        '&:hover': {
            color: 'white',
            backgroundColor: '#66738c',
        },
    }),
};

const SelectBox = ({
    name,
    errorText,
    label,
    id,
    options,
    className,
    classNamePrefix,
    tabIndex,
    isSearchable,
    labelClassname,
    outerClassName,
    isDisabled,
    tooltip,
    value,
    onChangeFunc,
    isClearable,
    isLoading,
    placeholder,
    classComp,
    isMulti,
    allowIcon,
    defaultValue,
    formatOptionLabel,
    menuPlacement = 'top',
    ...props
}: ISelectBoxProps) => (
    <>
        <div className={`w-full ${outerClassName}`}>
            {label && <label className={`fl-field-title-label ${labelClassname}`}>{label}</label>}
            <ReactSelect
                value={value}
                defaultValue={defaultValue}
                onChange={onChangeFunc}
                options={options}
                tabIndex={tabIndex}
                classNamePrefix={classNamePrefix ? classNamePrefix : 'fwpo_select'}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isDisabled={isDisabled}
                isLoading={isLoading}
                styles={colourStyles}
                placeholder={placeholder}
                className={`${classComp}`}
                formatOptionLabel={formatOptionLabel}
                menuPlacement={menuPlacement}
                isMulti={isMulti}
            />
        </div>
    </>
);

SelectBox.defaultProps = {
    name: '',
    errorText: '',
    label: '',
    tooltip: '',
    className: '',
    options: '',
    id: '',
    labelClassname: '',
    classComp: '',
};

export default SelectBox;
