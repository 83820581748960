import React from 'react';
import Header from './Header';
import { useLocation } from 'react-router-dom';
import { PATH } from 'src/constants/path';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { breakPoints } from 'src/constants/general';

interface IProps {
    children: React.ReactNode;
}

const AppLayout = ({ children }: IProps) => {
    const location = useLocation();
    const { isMobile }: any = useDeviceDetect();
    return (
        <>
            <div className="bg-lightgray">
                {location.pathname === PATH.JOBDETAILINFO ? (
                    <>
                        {isMobile < breakPoints.tablet && <Header />}
                        <div className="">{children}</div>
                    </>
                ) : (
                    <>
                        <Header />
                        <div className=" px-10 pt-8 layout_main min-h-[calc(100vh-80px)] max-lg:px-5 ">{children}</div>
                    </>
                )}
            </div>
        </>
    );
};
export default AppLayout;
