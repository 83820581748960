import React from 'react';
import Loader from '../Loader';

const TextWithIconButton = ({
    btnClassName,
    textClassName,
    imgClassName,
    icon,
    textName,
    altName,
    onClick,
    disabled = false,
    btnType = 'button',
    isLoader = false,
    color = '#000000',
    size = 35,
}: any) => (
    <button
        className={`flex gap-2 ${btnClassName} ${disabled ? 'opacity-40' : ''}`}
        onClick={() => {
            onClick();
        }}
        disabled={disabled}
        type={btnType}
    >
        {isLoader ? (
            <Loader color={color} loading={isLoader} size={size} />
        ) : (
            <>
                <div className={textClassName}>{textName}</div>
                <img className={imgClassName} src={icon} alt={altName} />
            </>
        )}
    </button>
);

export default TextWithIconButton;
