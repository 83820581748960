import React from 'react';
import { BsDot } from 'react-icons/bs';

const ProgressFail = ({ title, value, icon, classBg, data }: any) => (
    <div
        className={`w-full flex flex-col items-center ${
            title === 'In Progress' ? 'h-2/3 pt-5 pb-1' : 'h-1/3 py-5 justify-around'
        } mb-2 last:mb-0 px-3  bg-white rounded-2xl border border-gray10 max-md:w-[48.08%] max-md:justify-between max-md:h-auto max-md:mb-0`}
    >
        <div className="justify-start items-center gap-2 mb-1 flex">
            <div className={`w-8 h-8 p-1 rounded-full justify-center items-center flex ${classBg}`}>
                <img className="w-5 h-5" src={icon} alt="img" />
            </div>
            <div className="text-black80 text-sm font-medium leading-none">{title}</div>
        </div>
        <div className="self-stretch h-5 px-1 flex-col justify-center items-center gap-1 flex">
            <div className="text-black80 text-base font-bold">{value}</div>
        </div>
        {title === 'In Progress' && (
            <ul className={`mt-2 text-sm font-light text-black80 w-full`}>
                <li className="flex justify-between mb-1">
                    <span className="flex items-center">
                        <BsDot />
                        {'Parking'}
                    </span>
                    <span className=" font-semibold">{data?.inProgressJobWithParking}</span>
                </li>
                <li className="flex justify-between mb-1">
                    <span className="flex items-center">
                        <BsDot />
                        {'Flagging'}
                    </span>
                    <span className=" font-semibold">{data?.inProgressJobWithFlagging}</span>
                </li>
                <li className="flex justify-between">
                    <span className="flex items-center">
                        <BsDot />
                        {'Active Worker'}
                    </span>
                    {data?.length !== 0 && <span className=" font-semibold">{data?.totalActiveEmployee ? data?.totalActiveEmployee : 0}</span>}
                </li>
            </ul>
        )}
    </div>
);

export default ProgressFail;
