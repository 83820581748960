import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon } from 'src/assets/customSvg';
import logoutIcon from 'src/assets/svgs/logOutIcon.svg';
import navMoreIcon from 'src/assets/svgs/navMore.svg';
import profileImg from 'src/assets/img/profileImg.png';
import { PATH } from 'src/constants/path';
import { tokenDecode } from 'src/utils/function';
import moment from 'moment';
import ModalContentBlock from 'src/components/Common/ModalContentBlock';
import { useDispatch, useSelector } from 'react-redux';
import { jobSortFilterData } from 'src/redux/reducers/jobFilterSlice';
import { general, generalConstants } from 'src/constants/general';
import ConfirmationPopUp from 'src/components/Common/ConfirmationPopUp';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import ResponsiveButton from 'src/components/Responsive/ResponsiveButton';
import { RootState } from 'src/redux/store';

const Header = () => {
    const jobViewResponsiveGetData = useSelector((state: RootState) => state.jobViewResponsive);
    const dispatch = useDispatch();
    const [userData, setUserData] = useState<any>({});
    const [currentDate, setCurrentDate] = useState<any>(moment().format('D MMM. hh:mm a'));
    const [prevMinute, setPrevMinute] = useState(moment().minute());
    const location = useLocation();
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [responsiveNavOpen, setResponsiveNavOpen] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(modalRef, () => setResponsiveNavOpen(false));

    const logOut = () => {
        localStorage.removeItem('token');
        navigate(PATH.LOGIN);
    };
    useEffect(() => {
        getToken();
    }, []);
    useEffect(() => {
        const body = document.body;
        body.style.overflow = responsiveNavOpen ? 'hidden' : 'auto';
        return () => {
            body.style.overflow = 'auto';
        };
    }, [responsiveNavOpen]);
    useEffect(() => {
        const timer = setInterval(() => {
            const currentMinute = moment().minute();
            if (currentMinute !== prevMinute) {
                setCurrentDate(moment().format('D MMM. hh:mm a'));
                setPrevMinute(currentMinute);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const getToken = async () => {
        const token = await tokenDecode();
        setUserData(token);
    };
    const jobSortFilterManage = () => {
        const payload = {
            searchInput: '',
            filterApply: 0,
            page: 0,
            rows: 20,
            sortByDateName: 'Newest',
            sortByDateValue: 'new',
            sortByJobTypeName: '',
            sortByJobTypeId: '',
            location: '',
            employee: '',
            requestor: '',
            department: '',
            startDate: '',
            endDate: '',
            sortByCompanyName: '',
            sortByCompanyId: '',
            filterSelectedValue: general.INPROGRESS,
            filterSelectedLabel: 'In Progress',
        };
        dispatch(jobSortFilterData(payload));
    };
    const renderText = () => {
        if (location.pathname === PATH.HOME) {
            return 'Jobs';
        } else if (location.pathname === PATH.JOB) {
            return 'Your Jobs';
        } else if (location.pathname === PATH.ADMIN) {
            return 'Admins';
        } else if (location.pathname === PATH.JOBDETAILINFO) {
            return 'Detailed Info';
        } else {
            return 'Employees';
        }
    };
    return (
        <>
            <div ref={modalRef} className="hidden max-lg:block">
                {responsiveNavOpen && (
                    <>
                        <div className="bg-white fixed top-0 left-0 bottom-0 z-50 max-md:w-3/4 max-lg:w-[53.08%]">
                            <div className="overflow-y-auto h-full">
                                <div className="max-md:pt-10 max-md:pb-[30px] max-md:pl-[30px] flex items-center gap-[3.84%] max-lg:pt-[50px] max-lg:pb-[30px] max-lg:pl-[40px]">
                                    <div
                                        className={`max-md:w-[50px] max-md:h-[50px] max-lg:w-[60px] max-lg:h-[60px] ${
                                            location.pathname === PATH.HOME ? 'bg-black' : 'bg-gray10 home_icon'
                                        } rounded-lg flex justify-center items-center cursor-pointer`}
                                        onClick={() => {
                                            navigate(PATH.HOME);
                                            jobSortFilterManage();
                                            setResponsiveNavOpen(false);
                                        }}
                                    >
                                        <HomeIcon />
                                    </div>
                                    <div className="flex-col justify-start items-start inline-flex font-['Satoshi Variable']">
                                        {location.pathname === PATH.HOME && <div className="text-black max-md:text-lg font-black max-lg:text-xl">Welcome 👋</div>}
                                        <div className="justify-start items-end gap-2 inline-flex">
                                            <div className="text-black max-md:text-xs font-normal max-lg:text-sm">it’s: {currentDate}</div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="py-[30px] font-['Satoshi Variable'] font-bold text-base  border-y boredr-[#EFF0F1]">
                                    {userData?.userType === generalConstants.superAdmin && (
                                        <li
                                            className={`max-md:pl-[30px] py-[15px] max-lg:pl-[40px] ${
                                                location.pathname === PATH.ADMIN ? 'text-[#000000] shadow-md shadow-[#333333]' : 'text-[#80868D]'
                                            }`}
                                        >
                                            <p
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    navigate(PATH.ADMIN);
                                                    setResponsiveNavOpen(false);
                                                }}
                                            >
                                                Admin Administrator
                                            </p>
                                        </li>
                                    )}
                                    <li
                                        className={`max-md:pl-[30px] py-[15px] max-lg:pl-[40px] mt-5 ${
                                            location.pathname === PATH.JOB ? 'text-[#000000] shadow-md shadow-[#333333]' : 'text-[#80868D]'
                                        }`}
                                    >
                                        <p
                                            className="cursor-pointer"
                                            onClick={() => {
                                                navigate(PATH.JOB);
                                                setResponsiveNavOpen(false);
                                            }}
                                        >
                                            Jobs
                                        </p>
                                    </li>
                                    <li
                                        className={`max-md:pl-[30px] py-[15px] max-lg:pl-[40px] mt-5 ${
                                            location.pathname === PATH.EMPLOYEE ? 'text-[#000000] shadow-md shadow-[#333333]' : 'text-[#80868D]'
                                        }`}
                                    >
                                        <p
                                            className="cursor-pointer"
                                            onClick={() => {
                                                navigate(PATH.EMPLOYEE);
                                                jobSortFilterManage();
                                                setResponsiveNavOpen(false);
                                            }}
                                        >
                                            Employees
                                        </p>
                                    </li>
                                </ul>
                                <div className="mt-[30px] flex justify-center">
                                    <ResponsiveButton
                                        title={'Log out'}
                                        isMoreClass=" max-md:h-[40px]  max-md:w-[56.2%] max-lg:h-[50px] max-lg:w-[49.30%]"
                                        onclick={() => setModalOpen(true)}
                                        isColor={true}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className="fixed inset-0 bg-black opacity-50"></div> */}
                    </>
                )}
            </div>

            <div className="w-full pl-8 h-20 pr-12 py-2 bg-white rounded-b-[32px] flex justify-between items-center max-lg:px-5 max-lg:py-[10px] ">
                <div className="hidden max-lg:flex justify-between items-center w-full">
                    <span onClick={() => setResponsiveNavOpen(true)}>
                        <img src={navMoreIcon} alt="navMoreIcon" className="cursor-pointer w-[40px] h-[40px]" />
                    </span>
                    {location.pathname === PATH.JOB && jobViewResponsiveGetData?.responsiveCurrentJobStep === 0 ? (
                        <div>
                            <h2 className="font-['Satoshi Variable'] font-black max-md:text-xl max-lg:text-3xl">Your Jobs</h2>
                            {jobViewResponsiveGetData?.responsiveJobCompanyList?.length !== 0 && (
                                <div className="flex mt-1">
                                    {jobViewResponsiveGetData?.responsiveJobCompanyList?.map((data: any, index: number) => (
                                        <div className="font-['Satoshi Variable'] text-black80 max-md:text-xs font-medium mr-2 max-lg:text-sm" key={index}>
                                            {data.name}: {data.jobs}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ) : (
                        <h2 className="font-['Satoshi Variable'] font-black max-md:text-xl max-lg:text-3xl">{renderText()}</h2>
                    )}
                    <span>
                        <img className="max-md:w-[30px] max-md:h-[30px] max-lg:w-[40px] max-lg:h-[40px] rounded-3xl" src={profileImg} alt="profilpicture" />
                    </span>
                </div>
                <div className="px-6 py-4 flex  gap-3 justify-center items-center max-lg:hidden">
                    <div
                        className={`w-8 h-8 ${location.pathname === PATH.HOME ? 'bg-black' : 'bg-gray10 home_icon'} rounded-lg flex justify-center items-center cursor-pointer`}
                        onClick={() => {
                            navigate(PATH.HOME);
                            jobSortFilterManage();
                        }}
                    >
                        <HomeIcon />
                    </div>
                    <div className="flex-col justify-start items-start inline-flex">
                        {location.pathname === PATH.HOME && <div className="text-black text-sm font-black">Welcome 👋</div>}
                        <div className="justify-start items-end gap-2 inline-flex">
                            <div className="text-black text-xs font-normal">it’s: {currentDate}</div>
                        </div>
                    </div>
                </div>
                <ul className="justify-end items-center gap-4 flex max-lg:hidden">
                    {userData?.userType === generalConstants.superAdmin && (
                        <li className={`text-gray80 text-xs font-medium px-6 ${location.pathname === PATH.ADMIN ? 'employee_tab_active' : ''}`}>
                            <p
                                className="cursor-pointer"
                                onClick={() => {
                                    navigate(PATH.ADMIN);
                                }}
                            >
                                Admin Administrator
                            </p>
                        </li>
                    )}
                    <li className={`text-gray80 text-xs font-medium px-6 ${location.pathname === PATH.JOB ? 'employee_tab_active' : ''}`}>
                        <p
                            className="cursor-pointer"
                            onClick={() => {
                                navigate(PATH.JOB);
                            }}
                        >
                            Jobs
                        </p>
                    </li>
                    <li className={`text-gray80 text-xs font-medium px-6 ${location.pathname === PATH.EMPLOYEE ? 'employee_tab_active' : ''}`}>
                        <p
                            className="cursor-pointer"
                            onClick={() => {
                                navigate(PATH.EMPLOYEE);
                                jobSortFilterManage();
                            }}
                        >
                            Employees
                        </p>
                    </li>
                    <li className="pl-6  gap-3 flex justify-center items-center">
                        <div className="gap-2 flex justify-center items-center">
                            <img className="w-4 h-4 rounded-3xl" src={profileImg} alt="profilpicture" />
                            <div className="flex-col justify-start items-start inline-flex">
                                <div className="justify-start items-start gap-2 inline-flex">
                                    <div className="text-gray80 text-xs font-medium">{userData?.name}</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-6 h-6 p-0.5 bg-zinc-100 rounded-lg border border-gray10 flex justify-center items-center" onClick={() => setModalOpen(true)}>
                            <img src={logoutIcon} alt="logoutIcon" className="cursor-pointer" />
                        </div>
                    </li>
                </ul>
                {modalOpen && (
                    <ModalContentBlock onclose={() => setModalOpen(false)} isMoreClass="logout_modal" isModalOpen={modalOpen}>
                        <ConfirmationPopUp
                            confirmText="Are you sure you want to logout ?"
                            submitOnClick={() => {
                                logOut();
                                jobSortFilterManage();
                            }}
                            cancelOnClick={() => setModalOpen(false)}
                        />
                    </ModalContentBlock>
                )}
            </div>
        </>
    );
};

export default Header;
