import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppLayout from 'src/app/AppLayout';
import Employee from 'src/app/Employee';
import Home from 'src/app/Home';
import Job from 'src/app/Job';
import JobDetailInfo from 'src/app/JobDetailInfo';
import Login from 'src/app/Login';
import { ROUTES } from 'src/constants/routes';
import 'react-toastify/dist/ReactToastify.css';
import { companyDataAsync, departmentDataAsync, jobTypeDataAsync, locationDataAsync, requestorDataAsync } from 'src/redux/reducers/getCommonApiSlice';
import { useDispatch } from 'react-redux';
import ForgotPassword from 'src/app/ForgotPassword';
import Admin from 'src/app/Admin';
import { tokenDecode } from 'src/utils/function';
import { generalConstants } from 'src/constants/general';
import { PATH } from 'src/constants/path';
import { adminTimeSpent } from 'src/services/admin';
import moment from 'moment';

const PrivateRoute = ({ children }: any) => {
    if (localStorage.getItem('token')) {
        return <AppLayout>{children}</AppLayout>;
    } else {
        return <Navigate to={PATH.LOGIN} />;
    }
};

const PublicRoute = ({ children }: any) => {
    if (localStorage.getItem('token')) {
        return <Navigate to={PATH.HOME} />;
    } else {
        return children;
    }
};

const SuperAdminRoute = ({ children }: any) => {
    if (localStorage.getItem('token')) {
        const token: any = tokenDecode();
        if (token?.userType === generalConstants.superAdmin) {
            return <AppLayout>{children}</AppLayout>;
        } else {
            return <Navigate to={PATH.HOME} />;
        }
    } else {
        return <Navigate to={PATH.LOGIN} />;
    }
};
const MainRoutes = () => {
    const token1: any = tokenDecode();
    const dispatch = useDispatch();
    const timeout = 600000; // 10 minutes in milliseconds
    const [lastActive, setLastActive] = useState(Date.now());
    const resetTimer = () => {
        setLastActive(Date.now());
    };
    const logOut = () => {
        localStorage.removeItem('token');
        window.location.href = PATH.LOGIN;
    };

    useEffect(() => {
        if (token1?.userType !== generalConstants.superAdmin) {
            const handleActivity = () => resetTimer();
            // window.addEventListener('mousemove', handleActivity);
            window.addEventListener('keydown', handleActivity);
            window.addEventListener('scroll', handleActivity);
            window.addEventListener('click', handleActivity);

            return () => {
                // window.removeEventListener('mousemove', handleActivity);
                window.removeEventListener('keydown', handleActivity);
                window.removeEventListener('scroll', handleActivity);
                window.removeEventListener('click', handleActivity);
            };
        }
    }, [resetTimer]);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (Date.now() - lastActive > timeout && localStorage.getItem('token') && token1?.userType !== generalConstants.superAdmin) {
                logOut();
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [lastActive, timeout]);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(companyDataAsync());
            dispatch(jobTypeDataAsync());
            dispatch(locationDataAsync());
            dispatch(departmentDataAsync());
            dispatch(requestorDataAsync());
            // Call the fetchData function immediately when the component mounts
            fetchData();

            // Call the fetchData function every 1 minute
            const intervalId = setInterval(fetchData, 60000); // 60000 milliseconds = 1 minute

            // Clean up the interval when the component unmounts
            return () => clearInterval(intervalId);
        }
    }, []);
    const fetchData = async () => {
        const currentDateTimeUTC = moment.utc().format('YYYY-MM-DD HH:mm:ss');
        const payload = {
            timeSpentInSec: generalConstants.spentTimeInseconds,
            currentDateTime: currentDateTimeUTC,
        };
        const everyOneMinute = await adminTimeSpent(payload);
        if (everyOneMinute?.data?.status === true) {
        }
    };

    return (
        <Router>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <Routes>
                <Route
                    path={ROUTES.LOGIN}
                    element={
                        <PublicRoute>
                            <Login />
                        </PublicRoute>
                    }
                />
                <Route
                    path={ROUTES.FORGOTPASSWORD}
                    element={
                        <PublicRoute>
                            <ForgotPassword />
                        </PublicRoute>
                    }
                />
                <Route
                    path={ROUTES.JOBDETAILINFO}
                    element={
                        <PrivateRoute>
                            <JobDetailInfo />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={ROUTES.HOME}
                    element={
                        <PrivateRoute>
                            <Home />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={ROUTES.JOB}
                    element={
                        <PrivateRoute>
                            <Job />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={ROUTES.EMPLOYEE}
                    element={
                        <PrivateRoute>
                            <Employee />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={ROUTES.ADMIN}
                    element={
                        <SuperAdminRoute>
                            <Admin />
                        </SuperAdminRoute>
                    }
                />
            </Routes>
        </Router>
    );
};
export default MainRoutes;
