import { API } from 'src/constants/api';
import { axiosInterceptor } from 'src/utils/interceptors';

export const jobOverView = async (payload?: any) => {
    const uri = API.Home.jobOverView;
    const response = await axiosInterceptor.post(uri, payload);
    return response;
};

export const peopleOverView = async (payload?: any) => {
    const uri = API.Home.peopleOverView;
    const response = await axiosInterceptor.post(uri, payload);
    return response;
};

export const chartOverView = async (payload?: any) => {
    const uri = API.Home.chartOverView;
    const response = await axiosInterceptor.post(uri, payload);
    return response;
};
