import React, { forwardRef, useEffect, useState } from 'react';
import flagImg from 'src/assets/img/flagImg.png';
import { BsFillStarFill } from 'react-icons/bs';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TimePicker from 'react-time-picker';
import { convertedHours, dateTimeFormate, getRemovetzTime, roundedNumber, utcConverter } from 'src/utils/function';
import moment from 'moment';
import { addTimeSheet } from 'src/services/jobDetail';
import { toaster } from 'src/utils/toaster';
import DatePicker from 'react-datepicker';
import IconWithButton from 'src/components/Common/IconWithButton';
import closeIcon from 'src/assets/img/closeImg.png';
import calenderIcon from 'src/assets/svgs/calenderIcon.svg';
import backButtonImg from 'src/assets/img/employeeBackBtnImg.png';

const TimeSheet = ({ detailList, isSelectedDate, addTimeSheetSuccess, isBack = () => {} }: any) => {
    const [activeEmployeeId, setActiveEmployeeId] = useState(0);
    const [startTime, setStartTime] = useState('');
    const [timeSheets, setTimeSheets] = useState<any>([]);
    const [endTime, setEndTime] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [error, setError] = useState('');
    const [calenderOpen, setCalenderOpen] = useState(false);
    const [endDateCalenderOpen, setEndDateCalenderOpen] = useState(false);
    const [activeDatePicker, setActiveDatePicker] = useState<Date | null>(null);
    const [activeDatePickerEndDate, setActiveDatePickerEndDate] = useState<Date | null>(null);

    useEffect(() => {
        if (detailList) {
            setActiveEmployeeId(detailList?.employees[0]?.employeeId);
            setActiveDatePicker(foramteNewDate(getRemovetzTime(detailList?.startDate, 'YYYY-MM-DD')));
            // setActiveDatePicker(moment().toDate());
            setActiveDatePickerEndDate(foramteNewDate(getRemovetzTime(detailList?.startDate, 'YYYY-MM-DD')));
        }
    }, [detailList]);

    const newCheckPreFill = () => {
        let preTimeSheets = [];
        preTimeSheets = timeSheets.filter((item: any) => item.employeeId === activeEmployeeId);
        if (preTimeSheets.length === 0) {
            setStartTime('');
            setEndTime('');
            setActiveDatePicker(foramteNewDate(getRemovetzTime(detailList?.startDate, 'YYYY-MM-DD')));
            setActiveDatePickerEndDate(foramteNewDate(getRemovetzTime(detailList?.startDate, 'YYYY-MM-DD')));
        } else {
            const preStartTime = moment(preTimeSheets[0]?.startTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
            const PreEndTime = moment(preTimeSheets[0]?.endTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
            setStartTime(preStartTime);
            setEndTime(PreEndTime);
            setActiveDatePicker(foramteNewDate(preTimeSheets[0]?.startTime));
            setActiveDatePickerEndDate(foramteNewDate(preTimeSheets[0]?.endTime));
        }
    };

    useEffect(() => {
        if (activeEmployeeId) {
            newCheckPreFill();
        }
    }, [activeEmployeeId]);
    useEffect(() => {
        if (startTime && endTime) {
            const hour = caclculateDiff();
            if (!hour) {
                // setEndTime('');
                setError('end date cannot be less than start date');
                return;
            }
            const initObj = {
                jobId: detailList.id,
                employeeId: activeEmployeeId,
                date: moment(activeDatePicker).format('YYYY-MM-DD'),
                startTime: moment(`${moment(activeDatePicker).format('YYYY-MM-DD')} ${startTime}`).format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(`${moment(activeDatePickerEndDate).format('YYYY-MM-DD')} ${endTime}`).format('YYYY-MM-DD HH:mm:ss'),
                hours: hour,
            };

            const updatedTimeSheets = timeSheets.filter((item: any) => !(item.employeeId === initObj.employeeId));
            // Add the new initObj to timeSheets
            setTimeSheets([...updatedTimeSheets, initObj]);
        }
    }, [startTime, endTime, activeDatePicker, activeDatePickerEndDate]);
    const foramteNewDate = (isDate: any) => {
        const isFormattedDate = new Date(isDate);
        return isFormattedDate;
    };
    const caclculateDiff = () => {
        // const startMoment = moment(startTime, 'HH:mm');
        // const endMoment = moment(endTime, 'HH:mm');
        const startMoment = moment(`${moment(activeDatePicker).format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');
        const endMoment = moment(`${moment(activeDatePickerEndDate).format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm:ss');
        // Calculate the difference in minutes
        const durationInMinutes = endMoment.diff(startMoment, 'minutes');
        if (durationInMinutes <= 0) {
            const updatedTimeSheets = timeSheets.filter((item: any) => !(item.employeeId === activeEmployeeId));
            // Add the new initObj to timeSheets
            setTimeSheets([...updatedTimeSheets]);
            return false;
        } else {
            // Convert the duration to hours and minutes
            const hours = Math.floor(durationInMinutes / 60);
            const minutes = durationInMinutes % 60;
            const decimalHours = hours + minutes / 60;
            return decimalHours;
            // return `${hours}:${minutes}`;
        }
    };

    const timeOnChange = (e: any, type: string) => {
        setError('');
        if (type === 'start') {
            setStartTime(e);
        } else if (type === 'end') {
            setEndTime(e);
        }
    };

    const employeeOnclick = (data: any) => {
        setActiveEmployeeId(data.employeeId);
        setError('');
    };

    const saveTimeSheet = async () => {
        const updatedTimeSheet = timeSheets.map((data: any) => ({
            ...data,
            startTime: utcConverter(data.startTime, 'YYYY-MM-DD HH:mm:ss'),
            endTime: utcConverter(data.endTime, 'YYYY-MM-DD HH:mm:ss'),
        }));
        const payload = { timesheet: updatedTimeSheet };
        setBtnDisabled(true);
        const timeSheet = await addTimeSheet(payload);
        if (timeSheet.status === 200) {
            toaster('success', timeSheet.data.message);
            addTimeSheetSuccess();
        } else {
            toaster('error', timeSheet.data.message);
            setBtnDisabled(false);
        }
    };
    const onChange = (e: any) => {
        setActiveDatePicker(e);
        setError('');
    };
    const onChangeEndDate = (e: any) => {
        setActiveDatePickerEndDate(e);
        setError('');
    };
    const CustomStartInput = forwardRef((props, ref: any) => (
        <div ref={ref} className="sidebar_date">
            <IconWithButton
                title={`${dateTimeFormate(activeDatePicker, 'DD MMM, YYYY')}`}
                icon={calenderIcon}
                onclick={() => setCalenderOpen(true)}
                // isDisabled={isStatus.includes(step1Data.status) ? true : false}
            />
        </div>
    ));

    const CustomEndInput = forwardRef((props, ref: any) => (
        <div ref={ref} className="sidebar_date">
            <IconWithButton
                title={`${dateTimeFormate(activeDatePickerEndDate, 'DD MMM, YYYY')}`}
                icon={calenderIcon}
                onclick={() => setEndDateCalenderOpen(true)}
                // isDisabled={isStatus.includes(step1Data.status) ? true : false}
            />
        </div>
    ));
    return (
        <>
            <div className="max-h-[calc(100vh-60px)] overflow-y-auto max-lg:bg-white max-lg:p-4 max-lg:rounded-2xl">
                <div className="lg:hidden mb-5">
                    <div className="flex items-center gap-2">
                        <button className="max-md:h-6 max-md:w-6 max-lg:h-[30px] max-lg:w-[30px]" onClick={() => isBack()}>
                            <img src={backButtonImg} alt="backaerrowimg" />
                        </button>
                        <p className="font-black max-md:text-sm max-lg:text-base">Add Time Sheet</p>
                    </div>
                </div>
                <div className="flex max-md:gap-2.5 gap-10">
                    <div className=" w-1/4 max-lg:w-1/2 max-lg:h-[600px]  lg:h-[230px] overflow-auto time_sheet_left">
                        {detailList?.employees &&
                            detailList?.employees.map((data: any, index: number) => (
                                <div
                                    className={`border mb-[10px] px-3 py-2 me-3 rounded-md h-[80px] cursor-pointer ${data.employeeId === activeEmployeeId ? 'bg-gray10' : ''}`}
                                    key={index}
                                    onClick={() => employeeOnclick(data)}
                                >
                                    <h5 className="text-black text-xs font-medium font-['Satoshi Variable'] mb-2 truncate">{`${data.name} ${data.surname}`}</h5>
                                    <div className="flex gap-3 max-md:gap-2 mb-2">
                                        <div className="flex items-center gap-1">
                                            <div className="text-gray-500 text-xs font-normal font-['Satoshi Variable']">Jobs:</div>
                                            <div className="text-black text-xs font-normal font-['Satoshi Variable']">{data.totalJobs}</div>
                                        </div>
                                        <div className="flex items-center gap-1">
                                            <div className="text-gray-500 text-xs font-normal font-['Satoshi Variable']">Hrs:</div>
                                            <div className="text-black text-xs font-normal font-['Satoshi Variable']">{convertedHours(data.hours)}</div>
                                        </div>
                                        <div className="flex items-center gap-1">
                                            <BsFillStarFill className="h-3 w-3 text-gray80 mb-[2px]" />
                                            <div className="text-gray-500 text-xs font-normal font-['Satoshi Variable']">{roundedNumber(data.rating)}</div>
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-1">
                                        <img className="w-4 h-4 rounded-full" src={flagImg} alt="img" />
                                        <div className="text-zinc-800 text-xs font-normal font-['Satoshi Variable']">{data.languageFluency}</div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="max-md:w-1/2 max-lg:w-full w-[420px]">
                        <div className="lg:mt-6 max-lg:w-full">
                            <p> Job Start Date: {dateTimeFormate(isSelectedDate[0], 'DD MMMM')} </p>
                            <div className="mt-4 flex max-md:flex-col max-md:gap-4 gap-8 ">
                                <div className="max-lg:w-1/2 max-md:w-full">
                                    <p className="text-xs text-gray100 mb-2">Start Date</p>
                                    <DatePicker
                                        className="w-full"
                                        selected={activeDatePicker}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        open={calenderOpen}
                                        onClickOutside={() => setCalenderOpen(false)}
                                        customInput={<CustomStartInput />}
                                        showPopperArrow={false}
                                        calendarClassName="sort-custom-datepicker"
                                        minDate={foramteNewDate(isSelectedDate[0])}
                                    >
                                        <div className="inline-flex job-filter-btn pb-2 w-full">
                                            <IconWithButton title={`${dateTimeFormate(activeDatePicker, 'DD MMM, YYYY')}`} />
                                            <button className="ms-5" onClick={() => setCalenderOpen(false)}>
                                                <img src={closeIcon} className="w-6 h-6 rounded-full me-2" alt="closeIcon" />
                                            </button>
                                        </div>
                                    </DatePicker>
                                </div>
                                <div>
                                    <p className="text-xs text-gray100 mb-2">End Date</p>
                                    <DatePicker
                                        selected={activeDatePickerEndDate}
                                        onChange={(e) => {
                                            onChangeEndDate(e);
                                        }}
                                        open={endDateCalenderOpen}
                                        onClickOutside={() => setEndDateCalenderOpen(false)}
                                        customInput={<CustomEndInput />}
                                        showPopperArrow={false}
                                        calendarClassName="sort-custom-datepicker"
                                        minDate={foramteNewDate(isSelectedDate[0])}
                                    >
                                        <div className="inline-flex job-filter-btn pb-2">
                                            <IconWithButton title={`${dateTimeFormate(activeDatePickerEndDate, 'DD MMM, YYYY')}`} />
                                            <button className="ms-5" onClick={() => setEndDateCalenderOpen(false)}>
                                                <img src={closeIcon} className="w-6 h-6 rounded-full me-2" alt="closeIcon" />
                                            </button>
                                        </div>
                                    </DatePicker>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6">
                            <p className="text-sm font-normal">Please Indicate Time</p>
                            <div className="mt-2 flex max-lg:w-full max-md:flex-col max-md:gap-4 gap-8">
                                <div className="border p-2 max-md:w-full max-lg:w-1/2 rounded-xl">
                                    <p className="text-xs text-gray100">Start Time</p>
                                    <div className="w-32">
                                        <TimePicker
                                            onChange={(e) => timeOnChange(e, 'start')}
                                            value={startTime}
                                            clearIcon={null}
                                            clockIcon={null}
                                            disableClock={true}
                                            hourPlaceholder="00"
                                            minutePlaceholder="00"
                                            // maxTime="11:59"
                                            format="hh:mm a"
                                            className="popup-time-picker time_sheet_popup"
                                        />
                                    </div>
                                </div>
                                <div className="border p-2 max-md:w-full max-lg:w-1/2 rounded-xl">
                                    <p className="text-xs text-gray100">End Time</p>
                                    <div className="w-32">
                                        <TimePicker
                                            onChange={(e) => timeOnChange(e, 'end')}
                                            value={endTime}
                                            clearIcon={null}
                                            clockIcon={null}
                                            disableClock={true}
                                            hourPlaceholder="00"
                                            minutePlaceholder="00"
                                            // maxTime="11:59"
                                            format="hh:mm a"
                                            className="popup-time-picker time_sheet_popup"
                                        />
                                    </div>
                                </div>
                            </div>
                            {error && <p className="text-red-600 mt-1 text-sm">{error}</p>}
                        </div>
                    </div>
                </div>
                <div className="flex justify-end max-lg:hidden">
                    <button
                        className={`w-46 h-12 px-6 py-4 ${btnDisabled || timeSheets.length === 0 ? 'bg-[#D0D7F8]' : 'bg-secondary'}
                     rounded-3xl shadow justify-center items-center gap-2 inline-flex text-white text-sm font-bold font-['Satoshi Variable'] uppercase`}
                        onClick={() => {
                            saveTimeSheet();
                        }}
                        disabled={btnDisabled || timeSheets.length === 0 ? true : false}
                        type={'button'}
                    >
                        save
                    </button>
                </div>
            </div>
            <div className="flex justify-end lg:hidden mt-5">
                <button
                    className={`w-full h-12 px-6 py-4 ${btnDisabled || timeSheets.length === 0 ? 'bg-[#D0D7F8]' : 'bg-secondary'}
             rounded-3xl shadow justify-center items-center gap-2 inline-flex text-white text-sm font-bold font-['Satoshi Variable'] uppercase`}
                    onClick={() => {
                        saveTimeSheet();
                    }}
                    disabled={btnDisabled || timeSheets.length === 0 ? true : false}
                    type={'button'}
                >
                    save
                </button>
            </div>
        </>
    );
};

export default TimeSheet;
