import { createSlice } from '@reduxjs/toolkit';
import { general } from 'src/constants/general';

interface FilterState {
    searchInput: string;
    filterApply: any;
    page: any;
    rows: any;
    sortByDateName: string;
    sortByDateValue: string;
    sortByJobTypeName: string;
    sortByJobTypeId: any;
    location: string;
    employee: string;
    requestor: string;
    department: string;
    startDate: string;
    endDate: string;
    sortByCompanyName: string;
    sortByCompanyId: any;
    filterSelectedValue: string;
    filterSelectedLabel: string;
}

const initialState: FilterState = {
    searchInput: '',
    filterApply: 0,
    page: 0,
    rows: 20,
    sortByDateName: 'Newest',
    sortByDateValue: 'new',
    sortByJobTypeName: '',
    sortByJobTypeId: '',
    location: '',
    employee: '',
    requestor: '',
    department: '',
    startDate: '',
    endDate: '',
    sortByCompanyName: '',
    sortByCompanyId: '',
    filterSelectedValue: general.INPROGRESS,
    filterSelectedLabel: 'In Progress',
};

const jobFilterSlice = createSlice({
    name: 'jobFilter',
    initialState,
    reducers: {
        jobSortFilterData(state, action) {
            return action.payload;
        },
    },
});

export const { jobSortFilterData } = jobFilterSlice.actions;
export default jobFilterSlice.reducer;
