import React, { useEffect, useState } from 'react';
import calenderIcon from 'src/assets/svgs/calenderIcon.svg';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import enGB from 'date-fns/locale/en-GB'; // Import the English (Great Britain) locale
import 'src/assets/css/global.css';
import 'react-time-picker/dist/TimePicker.css';
import nextAerrowIcon from 'src/assets/svgs/nextAerrowIcon.svg';
import IconWithButton from 'src/components/Common/IconWithButton';
import TextWithIconButton from 'src/components/Common/TextWithIconButton';
import SidebarContentBlock from 'src/components/Common/SidebarContentBlock';
import TimePicker from 'react-time-picker';
import { checkDifference, dateTimeFormate } from 'src/utils/function';
import moment from 'moment';
import closeIcon from 'src/assets/img/closeImg.png';
import { useDispatch, useSelector } from 'react-redux';
import { jobCreateData } from 'src/redux/reducers/jobCreateSlice';
import { RootState } from 'src/redux/store';
import ResponsiveTextWithIconButton from 'src/components/Responsive/ResponsiveTextWithIconButton';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { breakPoints } from 'src/constants/general';
import { jobViewResponsiveData } from 'src/redux/reducers/jobViewResponsiveSlice';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
registerLocale('en-GB', enGB); // Register the English (Great Britain) locale

const JobCreateStep1 = ({ setJobStep, listData, setListData, step1Data, setStep1Data }: any) => {
    const jobViewResponsiveGetData = useSelector((state: RootState) => state.jobViewResponsive);
    const dispatch = useDispatch();
    const { isMobile }: any = useDeviceDetect();
    const preJobCreateData = useSelector((state: RootState) => state.jobCreate);
    const [startDate, setStartDate] = useState<any>(preJobCreateData.startDate ? new Date(preJobCreateData.startDate) : null);
    const [endDate, setEndDate] = useState<any>(preJobCreateData.endDate ? new Date(preJobCreateData.endDate) : null);
    const [value, setValue] = useState('00:00');
    const [datePopUP, setDatePopUp] = useState('');
    const [formateStartDate, setFormateStartDate] = useState(preJobCreateData.formateStartDate);
    const [formateEndDate, setFormateEndDate] = useState(preJobCreateData.formateEndDate);
    const [error, setError] = useState({ jobConfirm: '', date: '' });
    const [position, setPosition] = useState({ left: 0, top: 0 });
    const [test, setTest] = useState(0);
    const [showTime, setShowTime] = useState({
        startConfirm: preJobCreateData.startConfirm,
        endConfirm: preJobCreateData.endConfirm,
        startTime: preJobCreateData.startTime,
        endTime: preJobCreateData.endTime,
        mainStartDate: preJobCreateData.mainStartDate,
        mainEndDate: preJobCreateData.mainEndDate,
    });
    // const toDay = moment();
    const minutes = 2;
    useEffect(() => {
        if (startDate) {
            const formattedNewStartDate = getNewDate(startDate, 'DD MMM, YYYY');
            setFormateStartDate(formattedNewStartDate);
            setFormateEndDate('');
        }
        if (endDate) {
            const formattedNewEndDate = getNewDate(endDate, 'DD MMM, YYYY');
            setFormateEndDate(formattedNewEndDate);
        } else {
            makeRequireDatesForamet(startDate, startDate);
        }
        if (startDate && endDate) {
            makeRequireDatesForamet(startDate, endDate);
        }
    }, [startDate, endDate]);
    useEffect(() => {
        if (isMobile) {
            setTest(test + 1);
        }
    }, [isMobile]);
    useEffect(() => {
        if (formateStartDate) {
            timeOnChangeManual(value);
        }
    }, [formateStartDate]);
    useEffect(() => {
        if (formateEndDate) {
            timeOnChangeManual(value);
        }
    }, [formateEndDate]);

    useEffect(() => {
        if (startDate) {
            setError((prevData: any) => ({
                ...prevData,
                date: '',
            }));
            setValue('00:00');
        }
    }, [startDate]);
    const makeRequireDatesForamet = (isStartDate: any, isEndDate: any) => {
        const formattedNewStartDate = getNewDate(isStartDate, 'YYYY-MM-DD');
        const formattedNewEndDate = getNewDate(isEndDate, 'YYYY-MM-DD');
        const startDateDiff = moment(formattedNewStartDate); // Assuming format YYYY-MM-DD
        const endDateDiff = moment(formattedNewEndDate);

        // Calculate difference in days
        const dateDifference = endDateDiff.diff(startDateDiff, 'days');

        // Get all dates between start and end date
        const allDates: any = [];
        for (let i = 0; i <= dateDifference; i++) {
            const currentDate = startDateDiff.clone().add(i, 'days');
            allDates.push(currentDate.format('YYYY-MM-DD'));
        }
        if (allDates.length !== 0) {
            setStep1Data((prevData: any) => ({
                ...prevData,
                selectedDate: allDates,
            }));
        }

        const startOfWeek = startDateDiff.clone().startOf('week');
        const formateStartOfWeek = startOfWeek.format('YYYY-MM-DD');
        const endOfWeek = startDateDiff.clone().endOf('week');
        const formateEndOfWeek = endOfWeek.format('YYYY-MM-DD');

        // Calculate the start and end dates of the previous week
        const startOfPrevWeek = startOfWeek.clone().subtract(1, 'week');
        // const formateStartOfPrevWeek = startOfPrevWeek.format('YYYY-MM-DD');

        const endOfNextWeek = endOfWeek.clone().add(1, 'week');
        // const formateEndOfNextWeek = endOfNextWeek.format('YYYY-MM-DD');

        if (startOfPrevWeek && endOfNextWeek) {
            setStep1Data((prevData: any) => ({
                ...prevData,
                startOfPrevWeek: formateStartOfWeek,
                endOfNextWeek: formateEndOfWeek,
            }));
        }
    };
    const getNewDate = (date: any, formate: string) => {
        const newDate = new Date(date);
        return dateTimeFormate(newDate, formate);
    };
    const onChange = (dates: any, event: any) => {
        setPosition((prevData) => ({
            left: event.pageX,
            top: event.pageY,
        }));
        const [start, end] = dates;
        const payload = {
            startDate: start ? start.toISOString() : '',
            endDate: end ? end.toISOString() : '',
        };
        dispatch(jobCreateData(payload));
        setStartDate(start);
        setEndDate(end);
        if (end) {
            setDatePopUp('endDate');
            setError((prevData: any) => ({
                ...prevData,
                date: '',
            }));
        } else {
            setError((prevData: any) => ({
                ...prevData,
                date: '',
            }));
            setDatePopUp('startDate');
            setShowTime((prevData) => ({
                ...prevData,
                startConfirm: false,
                endConfirm: false,
                startTime: '12:00 am',
                endTime: '12:00 am',
                mainStartDate: '',
                mainEndDate: '',
            }));
            setFormateStartDate('');
            setFormateEndDate('');
        }
    };

    const checkDisabled = () => {
        if (datePopUP === 'startDate') {
            if (showTime.startTime) {
                return false;
            }
            return true;
        } else if (datePopUP === 'endDate') {
            if (showTime.endTime) {
                return false;
            }
            return true;
        }
    };
    const confirmClick = async () => {
        await timeOnChangeManual(value);
        setError((prevData: any) => ({
            ...prevData,
            date: '',
        }));
        if (datePopUP === 'startDate') {
            setShowTime((prevData) => ({
                ...prevData,
                startConfirm: true,
            }));
        } else if (datePopUP === 'endDate') {
            if (checkDifference(showTime.mainStartDate, showTime.mainEndDate, minutes)) {
                setShowTime((prevData) => ({
                    ...prevData,
                    endConfirm: true,
                }));
            } else {
                setError((prevData: any) => ({
                    ...prevData,
                    date: 'end time cannot be less than start time',
                }));
                return;
            }
        }
        setDatePopUp('');
        setValue('00:00');
    };
    const timeOnChange = (e: any) => {
        setError((prevData: any) => ({
            ...prevData,
            date: '',
        }));
        setValue(e);
    };
    const timeOnChangeManual = (e: any) => {
        if (e) {
            const originalTime = e;

            // Parse the time using moment
            const formattedTime = moment(originalTime, 'HH:mm').format('hh:mm a');
            const originalDate = datePopUP === 'startDate' ? `${formateStartDate} ${e}` : `${formateEndDate} ${e}`;
            // Parse the date using moment
            const formattedDate = moment(originalDate, 'DD MMM, YYYY hh:mma').format('YYYY-MM-DD HH:mm:ss');
            if (datePopUP === 'startDate') {
                setShowTime((prevData) => ({
                    ...prevData,
                    startTime: formattedTime,
                    mainStartDate: formattedDate,
                }));
            } else if (datePopUP === 'endDate') {
                setShowTime((prevData) => ({
                    ...prevData,
                    endTime: formattedTime,
                    mainEndDate: formattedDate,
                }));
            }
        }
    };
    const checkValidation = () => {
        if (!step1Data.jobConfirmation) {
            setError((prevData) => ({
                ...prevData,
                jobConfirm: 'please enter confirmation number',
            }));
        } else if (step1Data.jobConfirmation?.length < 10) {
            setError((prevData) => ({
                ...prevData,
                jobConfirm: 'please enter last 5 digit confirmation number',
            }));
        } else if (!showTime.mainStartDate) {
            setError((prevData) => ({
                ...prevData,
                date: 'please first select start date',
            }));
        } else {
            const endDateCheck = moment(showTime.mainEndDate);
            const currentDateCheck = moment().startOf('day');
            if (endDateCheck.isBefore(currentDateCheck)) {
                setError((prevData) => ({
                    ...prevData,
                    date: 'end date cannot be in the past',
                }));
            } else {
                setStep1Data((prevData: any) => ({
                    ...prevData,
                    startDate: showTime.mainStartDate,
                    endDate: showTime.mainEndDate,
                }));

                const payload = {
                    startDate: startDate ? startDate.toISOString() : '',
                    endDate: endDate ? endDate.toISOString() : '',
                    formateStartDate: formateStartDate,
                    formateEndDate: formateEndDate,
                    startConfirm: showTime.startConfirm,
                    endConfirm: showTime.endConfirm,
                    startTime: showTime.startTime,
                    endTime: showTime.endTime,
                    mainStartDate: showTime.mainStartDate,
                    mainEndDate: showTime.mainEndDate,
                };
                dispatch(jobCreateData(payload));
                setJobStep(2);
            }
        }
    };
    const ResponsiveCheckValidation = () => {
        if (!step1Data.jobConfirmation) {
            setError((prevData) => ({
                ...prevData,
                jobConfirm: 'please enter confirmation number',
            }));
        } else if (step1Data.jobConfirmation?.length < 10) {
            setError((prevData) => ({
                ...prevData,
                jobConfirm: 'please enter last 5 digit confirmation number',
            }));
        } else {
            setJobStep(1);
            dispatch(jobViewResponsiveData({ responsiveCurrentJobStep: 2 }));
        }
    };

    const ResponsiveBackBtn = () => {
        setJobStep(1);
        dispatch(jobViewResponsiveData({ responsiveCurrentJobStep: 1 }));
    };
    return (
        <>
            <div className="p-8 flex bg-white rounded-3xl max-md:p-[15px] max-lg:p-0 max-md:bg-white max-lg:bg-lightgray">
                <div
                    className={` w-[20%] pr-10 border-r max-lg:border-r-0 max-md:w-full max-md:pr-0 max-lg:w-[45.57%] max-md:p-0 max-lg:p-[15px] 
                    bg-white max-lg:rounded-3xl max-md:rounded-none
                     ${isMobile < breakPoints.mobile ? (jobViewResponsiveGetData?.responsiveCurrentJobStep === 1 ? 'block' : 'hidden') : 'block'}`}
                >
                    <SidebarContentBlock listData={listData} setListData={setListData} step1Data={step1Data} setStep1Data={setStep1Data} error={error} setError={setError} />
                </div>
                <div
                    className={`bg-white pl-6 xl:pl-14 flex-1 ${
                        isMobile < breakPoints.mobile ? (jobViewResponsiveGetData?.responsiveCurrentJobStep === 2 ? 'block' : 'hidden') : 'max-md:hidden'
                    } max-md:p-0 max-lg:p-[15px] max-md:ml-0 max-lg:ml-7 max-lg:rounded-3xl max-md:rounded-none max-md:h-auto max-lg:h-fit`}
                >
                    <div className="flex gap-4 ms-4 sidebar_date max-lg:gap-0 max-lg:ms-0 max-lg:justify-between">
                        <IconWithButton
                            title={`${formateStartDate ? `${formateStartDate} / ${showTime.startTime}` : 'Start Date'}`}
                            icon={calenderIcon}
                            isMoreClass={`${formateStartDate ? 'max-md:!px-1 max-lg:!px-3' : ''}`}
                        />
                        <IconWithButton
                            title={`${formateStartDate ? (formateEndDate ? `${formateEndDate} / ${showTime.endTime}` : 'Pick an End Date') : 'No End Date'}`}
                            icon={calenderIcon}
                            isMoreClass={`${formateEndDate ? 'max-md:!px-1 max-lg:!px-3' : ''}`}
                        />
                    </div>

                    <div className="w-full job_creation_calendar relative mt-4 max-lg:hidden block">
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                            locale="en-GB"
                            monthsShown={2}
                            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                            dateFormatCalendar="LLLL"
                            calendarClassName="custom-datepicker"
                            // minDate={toDay.toDate()}
                        />
                        {error.date && <p className="text-red-600 mt-1 text-sm">{error.date}</p>}
                    </div>
                    <div className="w-full job_creation_calendar relative mt-4 max-lg:block hidden">
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                            locale="en-GB"
                            monthsShown={1}
                            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                            dateFormatCalendar="LLLL"
                            calendarClassName="custom-datepicker"
                            // minDate={toDay.toDate()}
                        />
                        {error.date && <p className="text-red-600 mt-1 text-sm">{error.date}</p>}
                    </div>
                    <div className="text-right mt-14 max-lg:hidden">
                        <TextWithIconButton
                            btnClassName="w-46 h-12 px-6 py-4 bg-secondary rounded-2xl btn-shadow justify-center items-center gap-2 inline-flex"
                            textClassName="text-white text-sm font-bold font-['Satoshi Variable'] uppercase"
                            imgClassName="w-6 h-6 relative"
                            icon={nextAerrowIcon}
                            textName="Next"
                            altName="nextaerrowicon"
                            onClick={() => {
                                checkValidation();
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={`hidden  mt-28 ${jobViewResponsiveGetData?.responsiveCurrentJobStep === 2 ? 'max-md:block' : ''}`}>
                <ResponsiveTextWithIconButton title={'Back'} IsIcon={IoArrowBackCircleOutline} onclick={() => ResponsiveBackBtn()} isDisabled={false} />
            </div>
            <div className={`hidden max-lg:block pt-8 pb-6 max-md:mt-0 max-lg:mt-8 ${jobViewResponsiveGetData?.responsiveCurrentJobStep === 2}`}>
                <ResponsiveTextWithIconButton
                    title={'Next'}
                    icon={nextAerrowIcon}
                    onclick={() => (isMobile < breakPoints.mobile ? (jobViewResponsiveGetData?.responsiveCurrentJobStep === 2 ? checkValidation() : ResponsiveCheckValidation()) : checkValidation())}
                    isDisabled={false}
                />
            </div>
            {datePopUP && (
                <div>
                    <div
                        className={`max-w-sm bg-white px-4 py-3 rounded-xl shadow-lg overflow-hidden z-10 job_creation_calendar`}
                        style={{
                            position: 'absolute',
                            left: position.left > 120 ? position.left - 120 : position.left,
                            top: isMobile < breakPoints.mobile ? position.top + 10 : position.top,
                            right: 'auto',
                            bottom: 'auto',
                        }}
                    >
                        <div className="flex justify-end">
                            <img
                                src={closeIcon}
                                alt="closeicon"
                                className="h-5 w-5 cursor-pointer mb-2"
                                onClick={() => {
                                    setDatePopUp('');
                                    setValue('00:00');
                                }}
                            />
                        </div>

                        <TimePicker
                            onChange={timeOnChange}
                            value={value}
                            clearIcon={null}
                            clockIcon={null}
                            disableClock={true}
                            hourPlaceholder="00"
                            minutePlaceholder="00"
                            // maxTime="11:59"
                            format="hh:mm a"
                            className="popup-time-picker"
                        />
                        <div className="mt-3 flex justify-center">
                            <div className="w-24 cursor-pointer h-6 px-6 bg-secondary rounded-xl justify-center items-center inline-flex">
                                <button
                                    className={`text-white text-sm font-medium font-['Satoshi Variable'] leading-none ${checkDisabled() ? 'opacity-40' : ''}`}
                                    onClick={confirmClick}
                                    disabled={checkDisabled()}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default JobCreateStep1;
