import React from 'react';
import sortImg from 'src/assets/img/jobSortImg.png';
import searchIcon from 'src/assets/svgs/searchIcon.svg';
import circleIcon from 'src/assets/svgs/plusCircleIcon.svg';
import disableSortImg from 'src/assets/img/disableSortImg.png';
import closeIcon from 'src/assets/svgs/closeIcon.svg';
import rightIcon from 'src/assets/svgs/rightIcon.svg';
import TextWithIconButton from 'src/components/Common/TextWithIconButton';
import SelectBox from 'src/components/Common/SelectBox/SelectBox';

const HeaderContentBlock = ({
    sortOnClick,
    closeOnClick,
    saveOnClick,
    addNewOnClick,
    nextStep,
    addNewBtnText,
    isEmploy,
    headerText,
    isDisabled,
    searchOnchange,
    jobCompanyList,
    searchInput,
    isAdmin = false,
    setFilterSelectData,
    filterSelectData,
}: any) => {
    const filterOptions = [
        { value: 'today', label: 'Today' },
        { value: 'yesterday', label: 'Yesterday' },
        { value: 'this_week', label: 'This week' },
        { value: 'this_month', label: 'This month' },
        { value: 'this_year', label: 'This year' },
        { value: 'last_7_days', label: 'Last 7 days' },
        { value: 'last_30_days', label: 'Last 30 days' },
        { value: 'all_time', label: 'All time' },
    ];
    const filterSelectFunction = (data: any) => {
        setFilterSelectData(data);
    };
    return (
        <div className="flex justify-between items-center max-lg:hidden">
            <div>
                <div className="flex">
                    <h2 className="text-black text-2xl font-black mr-2">{headerText}</h2>
                    {!isAdmin && (
                        <>
                            <button
                                className={`${nextStep ? 'opacity-40' : ''}`}
                                onClick={() => {
                                    sortOnClick();
                                }}
                                disabled={nextStep ? true : false}
                            >
                                <img src={nextStep ? disableSortImg : sortImg} alt="sortimage" className="h-6 w-6 mt-1" />
                            </button>
                        </>
                    )}
                </div>
                {nextStep || isEmploy
                    ? ''
                    : jobCompanyList?.length !== 0 && (
                          <div className="flex mt-3">
                              {jobCompanyList?.map((data: any, index: number) => (
                                  <div className="text-black80 text-xs font-medium mr-2" key={index}>
                                      {data.name}: {data.jobs}
                                  </div>
                              ))}
                          </div>
                      )}
            </div>
            <div>
                <div className="relative w-[430px]">
                    <input
                        className={`w-full h-14 px-5 py-2 pr-10 bg-white focus-visible:outline-none focus:border focus:border-primary rounded-3xl border border-gray10 placeholder:text-gray20 ${
                            nextStep ? 'opacity-40' : ''
                        }`}
                        placeholder="Search"
                        disabled={nextStep ? true : false}
                        onChange={searchOnchange}
                        value={searchInput}
                    />

                    <img className="w-6 h-6 absolute right-3 top-4" src={searchIcon} alt="searchicon" />
                </div>
            </div>
            <div>
                {nextStep ? (
                    <div className="flex gap-2">
                        <div
                            className="w-12 cursor-pointer h-12 p-1.5 bg-purpleLight rounded-3xl justify-center inline-flex items-center"
                            onClick={() => {
                                closeOnClick();
                            }}
                        >
                            <img src={closeIcon} alt="closeicon" className="h-3 w-3" />
                        </div>
                        <TextWithIconButton
                            btnClassName={`w-46 h-12 px-6 py-4 ${isDisabled ? 'bg-[#D0D7F8]' : 'bg-secondary'} rounded-3xl shadow justify-center items-center gap-2 inline-flex`}
                            textClassName="text-white text-sm font-bold uppercase"
                            imgClassName="w-6 h-6 relative"
                            icon={rightIcon}
                            textName="Save"
                            altName="righticon"
                            onClick={() => {
                                saveOnClick();
                            }}
                            disabled={isDisabled}
                            btnType="submit"
                        />
                    </div>
                ) : (
                    <div className="flex gap-5 items-center">
                        {isAdmin && (
                            <div className="dashboard_dropdown">
                                <SelectBox value={filterSelectData} onChangeFunc={filterSelectFunction} options={filterOptions} classComp="custom_react_select" menuPlacement="bottom" />
                            </div>
                        )}
                        <TextWithIconButton
                            btnClassName={`${isAdmin ? '' : 'w-full'} btn-secondary btn-shadow font-medium px-6 py-4 uppercase`}
                            icon={circleIcon}
                            textName={addNewBtnText}
                            altName="circleicon"
                            onClick={() => {
                                addNewOnClick();
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
export default HeaderContentBlock;
