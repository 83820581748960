import { API } from 'src/constants/api';
import { axiosInterceptor } from 'src/utils/interceptors';

export const adminList = async (payload?: any, signal?: any) => {
    const uri = API.Admin.list + payload;
    const response = await axiosInterceptor.get(uri, { signal: signal });
    return response;
};

export const createAdmin = async (payload?: any) => {
    const uri = API.Admin.create;
    const response = await axiosInterceptor.post(uri, payload);
    return response;
};

export const adminTimeSpent = async (payload?: any) => {
    const uri = API.Admin.timeSpent;
    const response = await axiosInterceptor.post(uri, payload);
    return response;
};
