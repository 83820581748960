import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputs = ({ formik, onChange, setNumber, phoneInputNumber = '', inputClass = '', buttonClass = '', countryName = '' }: any) => {
    const [phones, setPhones] = useState<string>('');
    useEffect(() => {
        if (phoneInputNumber) {
            setPhones(phoneInputNumber);
        }
    }, [phoneInputNumber]);

    useEffect(() => {
        if (countryName) {
            formik.setFieldValue('phoneNumber', '');
        }
    }, [countryName]);

    const handlePhoneChange = (phone: string, data: any, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
        let countryCode = formattedValue.split(' ')[0];
        const formattedNumber = phone !== '' ? `+${phone}` : '';
        if (countryCode.length - 1 === phone?.length) {
            formik.setErrors({ phoneNumber: 'Please enter proper phone number' });
            formik.setFieldValue('phoneNumber', '');
        } else {
            formik.setErrors({ phoneNumber: '' });
            formik.setFieldValue('phoneNumber', formattedNumber);
        }
        const desiredPart = phone.replace(formattedValue.split(' ')[0].substring(1), '');
        setNumber({
            countryCode: formattedValue.split(' ')[0],
            num: desiredPart,
            countryName: data.countryCode,
        });

        setPhones(phone);
    };

    return (
        <>
            <PhoneInput
                country={countryName ? countryName : 'us'}
                inputClass={inputClass}
                buttonClass={buttonClass}
                specialLabel="Phone Number"
                inputProps={{
                    name: 'phoneNumber',
                }}
                value={phones}
                onChange={(phone, data, event, formattedValue) => {
                    handlePhoneChange(phone, data, event, formattedValue);
                    onChange();
                }}
                onBlur={formik.handleBlur}
                countryCodeEditable={false}
                enableSearch={true}
                disableCountryGuess={true}
                disableDropdown={true}
            />
            {formik.errors.phoneNumber && formik.touched.phoneNumber && <p className="text-red-600 mt-1 text-sm">{formik.errors.phoneNumber}</p>}
        </>
    );
};

export default PhoneInputs;
