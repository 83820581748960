import moment from 'moment';
import { jwtDecode } from 'jwt-decode';

export const dateTimeFormate = (inputData: any, formate: any) => {
    const formattedDate = moment(inputData).format(formate);
    // const formattedDate = moment.utc(inputData).format(formate);
    return formattedDate;
};

export const tokenDecode = () => {
    const token = localStorage.getItem('token');
    if (token) {
        const decodedHeader = jwtDecode(token);
        return decodedHeader;
    }
};

export const utcConverter = (value: any, formate: string) => {
    // var istMoment = moment(value);
    // var utcMoment = istMoment.utc();
    // var utcDateString = istMoment.format(formate);
    var utcDateString = moment.utc(value).format(formate);
    return utcDateString;
};

export const convertedHoursMinutes = (decimalHours: any, type: string) => {
    const isConvertedHours = Math.floor(decimalHours);
    const isConvertedMinutes = Math.round((decimalHours - isConvertedHours) * 60);
    const paddedHours = String(isConvertedHours).padStart(2, '0');
    const paddedMinutes = String(isConvertedMinutes).padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}`;
};

export const convertedHours = (decimalHours: any) => {
    if (decimalHours !== undefined) {
        const isConvertedHours = Math.floor(decimalHours);
        return isConvertedHours;
    }
};

export const checkDifference = (mainStartDate: any, mainEndDate: any, minutes: number) => {
    const format = 'YYYY-MM-DD HH:mm:ss';
    const startDateMoment = moment(mainStartDate, format);
    const endDateMoment = moment(mainEndDate, format);
    const diffInMinutes = endDateMoment.diff(startDateMoment, 'minutes');
    if (diffInMinutes >= minutes) {
        return true;
    } else {
        return false;
    }
};

export const checkIsPastDate = (mainStartDate: any, mainEndDate: any) => {
    const format = 'YYYY-MM-DD HH:mm:ss';
    const startDateMoment = moment(mainStartDate, format);
    const endDateMoment = moment(mainEndDate, format);
    const currentDate = moment();
    const isMainStartDatePast = startDateMoment.isBefore(currentDate);
    const isMainEndDatePast = endDateMoment.isBefore(currentDate);
    if (isMainStartDatePast && isMainEndDatePast) {
        return true;
    } else {
        return false;
    }
};

export const roundedNumber = (number: any) => {
    if (number) {
        const pointAfterOneDigit = Math.round(number * 10) / 10;
        return pointAfterOneDigit;
    } else {
        return 0;
    }
};

export const defaultJobCreateData = {
    startDate: null,
    endDate: null,
    formateStartDate: '',
    formateEndDate: '',
    startConfirm: false,
    endConfirm: false,
    startTime: '00:00',
    endTime: '00:00',
    mainStartDate: '',
    mainEndDate: '',
};

export const getLocalDateTime = (utcDateTime: any) => {
    const localDateTime = moment.utc(utcDateTime).local().format('YYYY-MM-DD');
    return localDateTime;
};

export const newTest = () => {
    // const abc = moment('2024-03-15T19:00:00.000Z').format('YYYY-MM-DD');
    // const bbc = moment('2024-03-15 19:00:00.000').format('YYYY-MM-DD');
};

export const getRemovetzTime = (isDate: any, isFormate: string) => moment.utc(isDate).format(isFormate);

export const getCurrentDateTime = (isFormate: string) => moment().format(isFormate);

export const makeHoursAndMinutes = (value: number) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    if (value === 0) {
        return 0;
    } else if (value < 60) {
        return `${value} mintues`;
    } else if (value < 120) {
        return `${hours} hr ${minutes} mintues`;
    } else if (value >= 120) {
        return `${hours} hrs ${minutes} mintues`;
    }
};

export const checkImageOrNot = (str: string) => {
    const searchTerm = 'image';
    if (!str) {
        return false;
    } else if (str.includes(searchTerm)) {
        return true;
    } else {
        return false;
    }
};

export const getEmployeeOfTheHeading = (duration: string) => {
    let result;

    switch (duration) {
        case 'This week':
            result = 'Current Week';
            break;
        case 'Last Week':
            result = 'Last Week';
            break;
        case 'Last Month':
            result = 'Last Month';
            break;
        default:
            result = 'Last Year';
            break;
    }
    return result;
};

export const getUpDownRoundedNumber = (number: number) => {
    const integerPart = Math.floor(number);
    const decimalPart = number - integerPart;

    // Check if the decimal part is 0.5 or greater
    if (decimalPart >= 0.5) {
        return integerPart + 1;
    } else {
        return integerPart;
    }
};
