import React, { forwardRef, useEffect, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import IconWithButton from 'src/components/Common/IconWithButton';
import calenderIcon from 'src/assets/svgs/calenderIcon.svg';
import DatePicker from 'react-datepicker';
import { dateTimeFormate } from 'src/utils/function';
import { breakPoints, companyName, general } from 'src/constants/general';
import westImg from 'src/assets/img/westImg.png';
import logImg from 'src/assets/img/logImg.png';
import fsImg from 'src/assets/img/fsImg.png';
import { BsDot } from 'react-icons/bs';
import SelectBox from 'src/components/Common/SelectBox/SelectBox';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';

const JobSortFilter = ({ listData, sortBy, setSortBy, setFilterBy, filterBy, dateProps, setSelectedValue, selectedValue }: any) => {
    const { isMobile }: any = useDeviceDetect();
    const [calenderOpen, setCalenderOpen] = useState(false);
    const [calenderOpenEndDate, setCalenderOpenEndDate] = useState(false);
    const { startDate, setStartDate, formateStartDate, setFormateStartDate, endDate, setEndDate, setFormateEndDate, formateEndDate } = dateProps;

    useEffect(() => {
        if (startDate) {
            const formattedNewStartDate = getNewDate(startDate);
            setFormateStartDate(formattedNewStartDate);
            setFormateEndDate('End Date');
            setFilterBy((prevData: any) => ({
                ...prevData,
                startDate: dateTimeFormate(formattedNewStartDate, 'YYYY-MM-DD HH:mm:ss'),
            }));
        }
        if (endDate) {
            const formattedNewEndDate = getNewDate(endDate);
            setFormateEndDate(formattedNewEndDate);
            setFilterBy((prevData: any) => ({
                ...prevData,
                endDate: dateTimeFormate(formattedNewEndDate, 'YYYY-MM-DD HH:mm:ss'),
            }));
        }
    }, [startDate, endDate]);

    const getNewDate = (date: any) => {
        const newDate = new Date(date);
        return dateTimeFormate(newDate, 'DD MMM, YYYY');
    };

    const onChangeStartDate = (date: any) => {
        setStartDate(date);
        setCalenderOpen(false);
    };
    const onChangeEndDate = (date: any) => {
        setEndDate(date);
        setCalenderOpenEndDate(false);
    };

    const handleSelectChange = (event: any, type: string) => {
        if (type === 'location') {
            setFilterBy((prevData: any) => ({
                ...prevData,
                location: event.target.value,
            }));
        } else if (type === 'employee') {
            setFilterBy((prevData: any) => ({
                ...prevData,
                employee: event.target.value,
            }));
        } else if (type === 'requestor') {
            setFilterBy((prevData: any) => ({
                ...prevData,
                requestor: event.target.value,
            }));
        } else if (type === 'department') {
            setFilterBy((prevData: any) => ({
                ...prevData,
                department: event.target.value,
            }));
        }
    };
    const options = [
        {
            value: '',
            label: 'All',
        },
        {
            value: general.INPROGRESS,
            label: 'In Progress',
        },
        {
            value: general.DONE,
            label: 'Done',
        },
        {
            value: general.CANCELLED,
            label: 'Cancelled',
        },
    ];

    const selectFunction = (data: any) => {
        setSelectedValue(data);
    };
    const GetCurrentstatus = (label: any) => {
        const statusColors: any = {
            'In Progress': '#FFCC15',
            Done: '#36B845',
            Cancelled: '#7A3CE3',
        };
        const isColor = statusColors[label] || '';
        return isColor;
    };
    const formatOptionLabel = ({ valued, label, customAbbreviation }: any) => (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        marginLeft: '-5px',
                        marginTop: '2px',
                        width: '25px',
                    }}
                >
                    <BsDot size={28} style={{ color: GetCurrentstatus(label) }} />
                </div>
                <div>{label}</div>
            </div>
        </>
    );

    const CustomStartInput = forwardRef((props, ref: any) => (
        <div className="" ref={ref}>
            <IconWithButton title={formateStartDate} icon={calenderIcon} isMoreClass={'max-lg:py-3 max-lg:gap-2 max-lg:rounded-xl max-lg:w-full'} onclick={() => setCalenderOpen(true)} />
        </div>
    ));
    const CustomEmdInput = forwardRef((props, ref: any) => (
        <div className="" ref={ref}>
            <IconWithButton title={formateEndDate} icon={calenderIcon} isMoreClass={'max-lg:py-3 max-lg:gap-2 max-lg:rounded-xl max-lg:w-full'} onclick={() => setCalenderOpenEndDate(true)} />
        </div>
    ));
    return (
        <div className="mt-4 px-8 h-[calc(100vh-270px)] overflow-y-auto scrollbar-hide max-lg:px-5 max-lg:mt-0 max-lg:h-[calc(100vh-200px)] ">
            <h4 className="text-black text-base font-medium max-lg:font-bold max-md:text-base max-lg:text-lg">Sort By</h4>
            <div className="mt-3 max-lg:mt-5">
                <p className="text-xs mb-2">Date</p>
                <div className="flex gap-2 max-md:gap-1">
                    <IconWithButton
                        title={'Newest'}
                        isColor={sortBy.date.name === 'Newest' ? true : false}
                        onclick={() =>
                            setSortBy((prevData: any) => ({
                                ...prevData,
                                date: {
                                    name: 'Newest',
                                    value: 'new',
                                },
                            }))
                        }
                    />
                    <IconWithButton
                        title={'Oldest'}
                        isColor={sortBy.date.name === 'Oldest' ? true : false}
                        onclick={() =>
                            setSortBy((prevData: any) => ({
                                ...prevData,
                                date: {
                                    name: 'Oldest',
                                    value: 'old',
                                },
                            }))
                        }
                    />
                    <IconWithButton
                        title={'End Date'}
                        isColor={sortBy.date.name === 'End Date' ? true : false}
                        onclick={() =>
                            setSortBy((prevData: any) => ({
                                ...prevData,
                                date: {
                                    name: 'End Date',
                                    value: 'endDate',
                                },
                            }))
                        }
                    />
                    <IconWithButton
                        title={'All'}
                        isColor={sortBy.date.name === 'All' ? true : false}
                        onclick={() =>
                            setSortBy((prevData: any) => ({
                                ...prevData,
                                date: {
                                    name: 'All',
                                    value: '',
                                },
                            }))
                        }
                    />
                </div>
            </div>
            <div className="mt-4">
                <p className="text-xs mb-2">Job Type</p>
                <div className="flex gap-2 max-md:gap-1">
                    {listData.jobList?.map((data: any, index: number) => (
                        <div key={index}>
                            <IconWithButton
                                title={data.name}
                                isColor={sortBy.jobType.name === data.name ? true : false}
                                onclick={() =>
                                    setSortBy((prevData: any) => ({
                                        ...prevData,
                                        jobType: {
                                            name: data.name,
                                            id: data.id,
                                        },
                                    }))
                                }
                            />
                        </div>
                    ))}
                    {listData.jobList.length !== 0 && (
                        <IconWithButton
                            title={'All'}
                            isColor={sortBy.jobType.name === 'All' ? true : false}
                            onclick={() =>
                                setSortBy((prevData: any) => ({
                                    ...prevData,
                                    jobType: {
                                        name: 'All',
                                        id: '',
                                    },
                                }))
                            }
                        />
                    )}
                </div>
            </div>
            <div className="mt-4">
                <p className="text-xs mb-2">Company</p>
                <div className="flex gap-2 max-md:gap-1">
                    {listData.companyList?.map((data: any, index: number) => (
                        <div key={index}>
                            <IconWithButton
                                title={data?.name}
                                icon={data?.name === companyName.West ? westImg : data?.name === companyName.Log ? logImg : fsImg}
                                isColor={sortBy.company.name === data.name ? true : false}
                                onclick={() =>
                                    setSortBy((prevData: any) => ({
                                        ...prevData,
                                        company: {
                                            name: data.name,
                                            id: data.id,
                                        },
                                    }))
                                }
                            />
                        </div>
                    ))}
                    {listData.companyList.length !== 0 && (
                        <IconWithButton
                            title={'All'}
                            isColor={sortBy.company.name === 'All' ? true : false}
                            onclick={() =>
                                setSortBy((prevData: any) => ({
                                    ...prevData,
                                    company: {
                                        name: 'All',
                                        id: '',
                                    },
                                }))
                            }
                        />
                    )}
                </div>
            </div>
            <div className="mt-6">
                <h4 className="text-black text-base font-medium max-lg:font-bold max-md:text-base max-lg:text-lg">Filter by</h4>
                <div className="mt-3">
                    <div className="relative">
                        <select
                            id="selectBox"
                            className="select_floating peer"
                            value={filterBy.location}
                            onChange={(e) => {
                                handleSelectChange(e, 'location');
                            }}
                        >
                            <option value={''}>All</option>
                            {listData.locationList?.map((data: any, index: number) => (
                                <option key={index} value={data.id}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="selectBox" className="floating_label">
                            Location
                        </label>
                        <span className="absolute top-1/2 -translate-y-1/2 right-3 text-gray80">
                            <HiChevronDown size={20} />
                        </span>
                    </div>
                    <div className="mt-3">
                        <div className="relative">
                            <select
                                id="selectBoxEmployee"
                                className="select_floating peer"
                                value={filterBy.employee}
                                onChange={(e) => {
                                    handleSelectChange(e, 'employee');
                                }}
                            >
                                <option value={''}>All</option>
                                {listData.employeeList?.map((data: any, index: number) => (
                                    <option key={index} value={data.employeeId}>
                                        {`${data.name} ${data.surname}`}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="selectBoxEmployee" className="floating_label">
                                Employee
                            </label>
                            <span className="absolute top-1/2 -translate-y-1/2 right-3 text-gray80">
                                <HiChevronDown size={20} />
                            </span>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="relative">
                            <select
                                id="selectBoxRequestor"
                                className="select_floating peer"
                                value={filterBy.requestor}
                                onChange={(e) => {
                                    handleSelectChange(e, 'requestor');
                                }}
                            >
                                <option value={''}>All</option>
                                {listData.requestorList?.map((data: any, index: number) => (
                                    <option key={index} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="selectBoxRequestor" className="floating_label">
                                Requestor
                            </label>
                            <span className="absolute top-1/2 -translate-y-1/2 right-3 text-gray80">
                                <HiChevronDown size={20} />
                            </span>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="relative">
                            <select
                                id="selectBoxDepartment"
                                className="select_floating peer"
                                value={filterBy.department}
                                onChange={(e) => {
                                    handleSelectChange(e, 'department');
                                }}
                            >
                                <option value={''}>All</option>
                                {listData.departmentList?.map((data: any, index: number) => (
                                    <option key={index} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="selectBoxDepartment" className="floating_label">
                                Department
                            </label>
                            <span className="absolute top-1/2 -translate-y-1/2 right-3 text-gray80">
                                <HiChevronDown size={20} />
                            </span>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="relative job_dropdown">
                            <SelectBox value={selectedValue} onChangeFunc={selectFunction} options={options} formatOptionLabel={formatOptionLabel} />
                            <label htmlFor="selectBox" className="absolute top-1 left-[14px] text-xs text-zinc-500 font-normal font-['Satoshi Variable']">
                                Status
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`mt-6 mb-4 flex gap-2 max-lg:gap-0 max-lg:justify-between
             ${isMobile < breakPoints.mobile ? 'mobile_datepicker_responsive' : isMobile < breakPoints.tablet ? 'tablet_datepicker_responsive' : ''}`}
            >
                <DatePicker
                    selected={startDate}
                    onChange={onChangeStartDate}
                    open={calenderOpen}
                    onClickOutside={() => setCalenderOpen(false)}
                    customInput={<CustomStartInput />}
                    showPopperArrow={false}
                    calendarClassName="sort-custom-datepicker w-[270px]"
                    // minDate={toDay.toDate()}
                ></DatePicker>
                <DatePicker
                    selected={endDate}
                    onChange={onChangeEndDate}
                    open={calenderOpenEndDate}
                    onClickOutside={() => setCalenderOpenEndDate(false)}
                    customInput={<CustomEmdInput />}
                    showPopperArrow={false}
                    calendarClassName="sort-custom-datepicker w-[270px]"
                    // minDate={toDay.toDate()}
                ></DatePicker>
            </div>
        </div>
    );
};
export default JobSortFilter;
