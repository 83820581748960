import { API } from 'src/constants/api';
import { axiosInterceptor } from 'src/utils/interceptors';

export const jobList = async (payload?: any, signal?: any) => {
    const uri = API.Job.list + payload;
    const response = signal ? await axiosInterceptor.get(uri, { signal: signal }) : await axiosInterceptor.get(uri);
    return response;
};

export const jobInEmployeeList = async (payload?: any, signal?: any) => {
    const uri = payload ? API.Job.employeeList + payload : API.Job.employeeList;
    const response = signal ? await axiosInterceptor.get(uri, { signal: signal }) : await axiosInterceptor.get(uri);
    return response;
};

export const createJob = async (payload?: any) => {
    const uri = API.Job.create;
    const response = await axiosInterceptor.post(uri, payload);
    return response;
};

export const deleteJob = async (payload?: any) => {
    const uri = API.Job.delete + payload;
    const response = await axiosInterceptor.delete(uri);
    return response;
};
