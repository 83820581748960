import React from 'react';
import { IInputBox } from './InputBox.interface';

const InputBox = ({ error, onChange, onBlur, value, name, placeholder, label, index, type, inputId, className = 'bg-white', maxLength }: IInputBox) => (
    <div className="relative">
        <input
            name={name}
            type={type || 'text'}
            onChange={onChange}
            onBlur={onBlur}
            id={inputId}
            value={value}
            className={`block rounded-xl p-3 w-full text-sm text-black100 ${className} appearance-none focus:outline-1 focus:ring-0 focus:border-secondary`}
            placeholder={placeholder}
            maxLength={maxLength}
        />
        {error && <p className="text-red-600 mt-1 text-sm">{error}</p>}
    </div>
);

export default InputBox;
