import React, { useEffect, useState } from 'react';
import westImg from 'src/assets/img/westImg.png';
import logImg from 'src/assets/img/logImg.png';
import fsImg from 'src/assets/img/fsImg.png';
import { CompltedJobIcon, RecivedJobIcon } from 'src/assets/customSvg';
import progresIcon from 'src/assets/svgs/progressIcon.svg';
import failIcon from 'src/assets/svgs/failIcon.svg';
import SelectBox from 'src/components/Common/SelectBox/SelectBox';
import IconWithButton from 'src/components/Common/IconWithButton';
import ProgressFail from 'src/components/Home/ProgressFail';
import RecivedCompleted from 'src/components/Home/RecivedCompleted';
import MonthlyOverview from 'src/components/Home/MonthlyOverview';
import EmployOfWeek from 'src/components/Home/EmployeeOfWeek';
import TopRequestor from 'src/components/Home/TopRequestor';
import GoodPerformers from 'src/components/Home/GoodPerformers';
import { chartOverView, jobOverView, peopleOverView } from 'src/services/home';
import moment from 'moment';
import { breakPoints, companyName, generalConstants } from 'src/constants/general';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import ResponsiveButton from 'src/components/Responsive/ResponsiveButton';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import MonthlyOverviewJobType from 'src/components/Home/MonthlyOverviewJobType';
import { tokenDecode } from 'src/utils/function';

const Home = () => {
    const { isMobile }: any = useDeviceDetect();
    const allListData = useSelector((state: RootState) => state.getCommonApi);
    const [isCompanyList, setIsCompanyList] = useState([]);
    const [jobOverViewData, setJobOverViewData] = useState<any>([]);
    const [responsiveView, setResponsiveView] = useState('jobs');
    const [peopleOverViewData, setPeopleOverViewData] = useState([]);
    const [userData, setUserData] = useState<any>({});
    const [chartOverViewData, setChartOverViewData] = useState({
        completed: [],
        inCompleted: [],
        months: [],
        total: [],
    });
    const [chartOverViewJobTypeData, setChartOverViewJobTypeData] = useState({
        flagging: [],
        parking: [],
        months: [],
    });
    const [jobCompany, setJobCompany] = useState({
        name: '',
        id: 0,
    });
    const [peopleCompany, setPeopleCompany] = useState({
        name: '',
        id: 0,
    });
    const [selectData, setSelectData] = useState({
        jobs: { value: 'yesterday', label: 'Yesterday' },
        people: { value: 'last_week', label: 'Last Week' },
    });
    const jobOptions = [
        { value: 'today', label: 'Today' },
        { value: 'yesterday', label: 'Yesterday' },
        { value: 'this_week', label: 'This week' },
        { value: 'this_month', label: 'This month' },
        { value: 'this_year', label: 'This year' },
        { value: 'last_7_days', label: 'Last 7 days' },
        { value: 'last_30_days', label: 'Last 30 days' },
        { value: 'all_time', label: 'All time' },
    ];
    const peopleOptions = [
        { value: 'this_week', label: 'This week' },
        { value: 'last_week', label: 'Last Week' },
        { value: 'last_month', label: 'Last Month' },
        { value: 'last_year', label: 'Last Year' },
    ];
    const total = 'Total';
    const all = 'All';
    const jobSelectFunction = (data: any) => {
        setSelectData((preavData) => ({
            ...preavData,
            jobs: data,
        }));
    };
    const peopleSelectFunction = (data: any) => {
        setSelectData((preavData) => ({
            ...preavData,
            people: data,
        }));
    };
    const getToken = async () => {
        const token = await tokenDecode();
        setUserData(token);
    };
    useEffect(() => {
        getToken();
    }, []);
    useEffect(() => {
        getDetail();
    }, [allListData?.companyData]);
    useEffect(() => {
        if (jobCompany?.name) {
            geJobDetail();
        }
    }, [jobCompany?.name, selectData?.jobs?.value]);
    useEffect(() => {
        if (jobCompany?.name) {
            getChartDetail();
        }
    }, [jobCompany?.name]);
    useEffect(() => {
        if (peopleCompany?.name) {
            gePeopleDetail();
        }
    }, [peopleCompany?.name, selectData?.people?.value]);

    const getDetail = async () => {
        setIsCompanyList(allListData?.companyData);
        setJobCompany({ name: allListData?.companyData[0]?.name, id: allListData?.companyData[0]?.id });
        setPeopleCompany({ name: allListData?.companyData[0]?.name, id: allListData?.companyData[0]?.id });
    };
    const geJobDetail = async () => {
        let payload;
        if (jobCompany?.name === total) {
            payload = {
                dataRange: selectData?.jobs?.value,
            };
        } else {
            payload = {
                companyId: jobCompany?.id,
                dataRange: selectData?.jobs?.value,
            };
        }
        const jobView = await jobOverView(payload);
        setJobOverViewData(jobView?.data?.data);
    };
    const roundDown = (num: number) => Math.floor(num);
    const getChartDetail = async () => {
        let payload;
        if (jobCompany?.name === total) {
            payload = '';
        } else {
            payload = {
                companyId: jobCompany?.id,
            };
        }
        const chartView = await chartOverView(payload);
        const monthNames = chartView?.data?.data?.map((item: any) => moment(item.monthName, 'MMMM').format('MMM'));
        const completeds = chartView?.data?.data?.map((item: any) => item.completed);
        const inCompleteds = chartView?.data?.data?.map((item: any) => item.incompleted);
        const totalIncomingJobs = chartView?.data?.data?.map((item: any) => item.total);
        const jobTypeMonthNames = chartView?.data?.monthlyOverviewByJobTypes?.map((item: any) => moment(item.monthName, 'MMMM').format('MMM'));
        const jobTypeFlagging = chartView?.data?.monthlyOverviewByJobTypes?.map((item: any) => roundDown(item.flaggingHours));
        const jobTypeParking = chartView?.data?.monthlyOverviewByJobTypes?.map((item: any) => roundDown(item.parkingHours));

        setChartOverViewData((prevState) => ({
            ...prevState,
            completed: completeds,
            inCompleted: inCompleteds,
            months: monthNames,
            total: totalIncomingJobs,
        }));
        setChartOverViewJobTypeData((prevState) => ({
            ...prevState,
            flagging: jobTypeFlagging,
            parking: jobTypeParking,
            months: jobTypeMonthNames,
        }));
    };
    const gePeopleDetail = async () => {
        let payload;
        if (peopleCompany?.name === all) {
            payload = {
                dataRange: selectData?.people?.value,
            };
        } else {
            payload = {
                companyId: peopleCompany?.id,
                dataRange: selectData?.people?.value,
            };
        }
        const peopleView = await peopleOverView(payload);
        setPeopleOverViewData(peopleView?.data?.data);
    };
    return (
        <div className="flex flex-wrap">
            <div className="hidden w-full justify-between max-lg:flex">
                <ResponsiveButton
                    title={'Jobs'}
                    isMoreClass=" max-md:h-[40px]  max-md:w-[47.2%] max-lg:h-[50px] max-lg:w-[48%]"
                    onclick={() => setResponsiveView('jobs')}
                    isColor={responsiveView === 'jobs' ? true : false}
                />
                <ResponsiveButton
                    title={'People'}
                    isMoreClass=" max-md:h-[40px]  max-md:w-[47.2%] max-lg:h-[50px] max-lg:w-[48%]"
                    onclick={() => setResponsiveView('peoples')}
                    isColor={responsiveView === 'peoples' ? true : false}
                />
            </div>
            <div className={`w-[48%] xl:w-1/2 pe-4 max-lg:${responsiveView === 'jobs' ? 'block' : 'hidden'} max-lg:w-full max-md:mt-[30px] max-lg:pe-0 max-lg:mt-[35px]`}>
                <div className="flex justify-between">
                    <div className="left">
                        <h2 className="text-black text-sm font-black font-['Satoshi Variable']">Jobs `Overview</h2>
                        <div className="flex justify-normal gap-2 mt-3 max-md:gap-1">
                            {isCompanyList.length !== 0 && (
                                <>
                                    {isCompanyList?.map((data: any, index: number) => (
                                        <div key={index}>
                                            <IconWithButton
                                                title={data?.name}
                                                icon={data?.name === companyName.West ? westImg : data?.name === companyName.Log ? logImg : fsImg}
                                                isColor={jobCompany.name === data.name ? true : false}
                                                onclick={() =>
                                                    setJobCompany({
                                                        name: data.name,
                                                        id: data.id,
                                                    })
                                                }
                                            />
                                        </div>
                                    ))}
                                    <div>
                                        <IconWithButton
                                            title={total}
                                            isColor={jobCompany.name === total ? true : false}
                                            onclick={() =>
                                                setJobCompany({
                                                    name: total,
                                                    id: 0,
                                                })
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className={`dashboard_dropdown ${isMobile < breakPoints.mobile ? 'dashboard_dropdown_responsive' : ''}`}>
                        <SelectBox value={selectData.jobs} onChangeFunc={jobSelectFunction} options={jobOptions} classComp="custom_react_select" menuPlacement="bottom" />
                    </div>
                </div>
                <div className="flex pt-5 max-md:flex-wrap max-lg:justify-between">
                    <div className="w-xxl-2/5 w-[35%] max-md:w-[48.08%] max-lg:w-[31%]">
                        <RecivedCompleted
                            title={'Received Jobs'}
                            value={jobOverViewData?.receivedJob?.total}
                            isColor={true}
                            icon={<RecivedJobIcon />}
                            arrayType={jobOverViewData?.receivedJob?.receivedJobType}
                            jobTotal="receivedJobTotal"
                        />
                    </div>
                    {/* <div className="w-xxl-3/5 w-[65%] flex"> */}
                    <div className="px-2 w-xxl-2/5 w-[29.25%] flex flex-col max-md:order-2 max-md:flex-row max-md:w-full max-md:mt-3 max-md:px-0 max-md:justify-between max-lg:w-[31%]">
                        <ProgressFail title={'In Progress'} value={jobOverViewData?.inProgressJob} icon={progresIcon} classBg="bg-yellow-50" data={jobOverViewData} />
                        <ProgressFail title={'Failed Jobs'} value={jobOverViewData?.failedJob} icon={failIcon} classBg="bg-red-50" />
                    </div>
                    <div className="w-xxl-3/5 w-[35.75%] max-md:w-[48.08%] max-lg:w-[31%]">
                        <RecivedCompleted
                            title={'Completed jobs'}
                            value={jobOverViewData?.completedJob?.total}
                            isColor={false}
                            icon={<CompltedJobIcon />}
                            arrayType={jobOverViewData?.completedJob?.completedJobType}
                            jobTotal="completedJobTotal"
                        />
                    </div>
                    {/* </div> */}
                </div>
                <MonthlyOverview chartOverViewData={chartOverViewData} jobCompany={jobCompany} />
                {userData?.userType === generalConstants.superAdmin && <MonthlyOverviewJobType chartOverViewJobTypeData={chartOverViewJobTypeData} jobCompany={jobCompany} />}
            </div>
            <div className={`w-[52%] xl:w-1/2 ps-xxl-4 ps-0 max-lg:${responsiveView === 'peoples' ? 'block' : 'hidden'} max-lg:w-full max-md:mt-[30px] max-lg:pe-0 max-lg:mt-[35px]`}>
                <div className="flex justify-between">
                    <div className="pr-3">
                        <h2 className="text-black text-sm font-black font-['Satoshi Variable']">People `Overview</h2>
                        <div className="flex justify-normal gap-2 mt-3 max-md:gap-1">
                            {isCompanyList.length !== 0 && (
                                <>
                                    {isCompanyList?.map((data: any, index: number) => (
                                        <div key={index}>
                                            <IconWithButton
                                                title={data?.name}
                                                icon={data?.name === companyName.West ? westImg : data?.name === companyName.Log ? logImg : fsImg}
                                                isColor={peopleCompany.name === data.name ? true : false}
                                                onclick={() =>
                                                    setPeopleCompany({
                                                        name: data.name,
                                                        id: data.id,
                                                    })
                                                }
                                            />
                                        </div>
                                    ))}
                                    <div>
                                        <IconWithButton
                                            title={all}
                                            isColor={peopleCompany.name === all ? true : false}
                                            onclick={() =>
                                                setPeopleCompany({
                                                    name: all,
                                                    id: 0,
                                                })
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={`dashboard_dropdown ${isMobile < breakPoints.mobile ? 'dashboard_dropdown_responsive' : ''}`}>
                        <SelectBox value={selectData.people} onChangeFunc={peopleSelectFunction} options={peopleOptions} menuPlacement="bottom" />
                    </div>
                </div>
                <div className="pt-5 flex gap-4 max-md:flex-col max-md:w-full">
                    <div className="p-4 w-1/2 bg-white rounded-2xl max-md:w-full">
                        <EmployOfWeek peopleOverViewData={peopleOverViewData} duration={selectData?.people?.label} />
                    </div>
                    <div className="py-4 w-1/2 bg-white rounded-2xl flex flex-col max-md:w-full">
                        <TopRequestor peopleOverViewData={peopleOverViewData} userData={userData} />
                    </div>
                </div>
                <div className="mt-4 bg-white rounded-2xl max-md:my-4">
                    <GoodPerformers peopleOverViewData={peopleOverViewData} userData={userData} />
                </div>
            </div>
        </div>
    );
};
export default Home;
