import { API } from 'src/constants/api';
import { axiosInterceptor } from 'src/utils/interceptors';

export const companyList = async (payload?: any) => {
    const uri = API.Additional.companyList;
    const response = await axiosInterceptor.get(uri, payload);
    return response;
};

export const jobTypesList = async (payload?: any) => {
    const uri = API.Additional.jobTypes;
    const response = await axiosInterceptor.get(uri, payload);
    return response;
};

export const locationList = async (payload?: any) => {
    const uri = API.Additional.locations;
    const response = await axiosInterceptor.get(uri, payload);
    return response;
};

export const departmentList = async (payload?: any) => {
    const uri = API.Additional.departments;
    const response = await axiosInterceptor.get(uri, payload);
    return response;
};

export const requestorList = async (payload?: any) => {
    const uri = API.Additional.requestors;
    const response = await axiosInterceptor.get(uri, payload);
    return response;
};
