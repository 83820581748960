import React from 'react';

const ResponsiveButton = ({ title, icon, isColor = false, onclick, isDisabled = false, isMoreClass = '' }: any) => (
    <button
        className={`${isDisabled ? 'opacity-40' : ''}px-4 py-2 rounded-full justify-center items-center gap-1 flex cursor-pointer ${
            isColor ? 'bg-black text-white border-black' : 'text-black border border-gray20'
        } ${isMoreClass}`}
        onClick={onclick}
        disabled={isDisabled}
    >
        {icon && <img className="w-4 h-4 rounded-full" src={icon} alt="img" />}
        <div className="text-sm font-bold font-['Satoshi Variable'] whitespace-nowrap">{title}</div>
    </button>
);

export default ResponsiveButton;
