import { API } from 'src/constants/api';
import { axiosInterceptor } from 'src/utils/interceptors';

export const employeeList = async (payload?: any, signal?: any) => {
    const uri = API.Employee.list + payload;
    const response = signal ? await axiosInterceptor.get(uri, { signal: signal }) : await axiosInterceptor.get(uri);
    return response;
};

export const createEmployee = async (payload?: any) => {
    const uri = API.Employee.create;
    const response = await axiosInterceptor.post(uri, payload);
    return response;
};

export const employeeDetailById = async (payload?: any) => {
    const uri = API.Employee.detailById + payload;
    const response = await axiosInterceptor.get(uri);
    return response;
};

export const updateEmployee = async (payload?: any) => {
    const uri = API.Employee.update + payload.employeeId;
    const response = await axiosInterceptor.post(uri, payload);
    return response;
};

export const deleteEmployee = async (payload?: any) => {
    const uri = API.Employee.delete + payload;
    const response = await axiosInterceptor.delete(uri);
    return response;
};
