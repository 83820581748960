import React, { useEffect } from 'react';
import closeIcon from 'src/assets/img/closeImg.png';

const SortFilterContentModal = ({ setSortModal, onFilterClear, onFilterApply, children, isJob = false, isModalOpen }: any) => {
    useEffect(() => {
        const body = document.body;
        body.style.overflow = isModalOpen ? 'hidden' : 'auto';
        return () => {
            body.style.overflow = 'auto';
        };
    }, [isModalOpen]);
    useEffect(() => {
        const handleEsc = (event: any) => {
            if (event.keyCode === 27) {
                setSortModal();
            }
        };

        if (isModalOpen) {
            document.addEventListener('keydown', handleEsc);
        }

        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, [isModalOpen]);
    return (
        <>
            <div className="fixed top-24 left-0 flex items-center justify-center z-50 max-lg:top-0 max-lg:block max-lg:w-full max-lg:h-full">
                <div className={`${isJob ? 'w-96' : 'w-80'} bg-white rounded-tr-3xl rounded-br-3xl border border-lightgray shadow-lg max-md:w-[80%] max-lg:w-[52%] max-lg:h-full`}>
                    <div className="mt-2 flex justify-end max-md:mt-10 px-5 max-lg:mt-[50px]">
                        <img
                            src={closeIcon}
                            alt="closeicon"
                            className="h-6 w-6 cursor-pointer"
                            onClick={() => {
                                setSortModal(false);
                            }}
                        />
                    </div>
                    {children}
                    <div className="mt-6 text-center mb-8 flex justify-center gap-4 max-lg:mt-10 max-md:gap-[15.15%] max-lg:gap-[12.15%]">
                        <p className="text-violet-600 text-xs font-medium cursor-pointer" onClick={onFilterApply}>
                            Apply filter
                        </p>
                        <p className="text-violet-600 text-xs font-medium cursor-pointer" onClick={onFilterClear}>
                            Clear filter
                        </p>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black opacity-50"></div>
        </>
    );
};
export default SortFilterContentModal;
