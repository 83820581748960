export const APP_ROOT = '/';

export const ROUTES = {
    LOGIN: `${APP_ROOT}`,
    HOME: `${APP_ROOT}home`,
    JOB: `${APP_ROOT}job`,
    EMPLOYEE: `${APP_ROOT}employee`,
    JOBDETAILINFO: `${APP_ROOT}jobdetail`,
    FORGOTPASSWORD: `${APP_ROOT}forgotpassword`,
    ADMIN: `${APP_ROOT}admin`,
};
