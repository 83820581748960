import { createSlice } from '@reduxjs/toolkit';

interface JobViewResponsive {
    responsiveCurrentJobStep: number;
    responsiveJobCompanyList: any;
}

const initialState: JobViewResponsive = {
    responsiveCurrentJobStep: 0,
    responsiveJobCompanyList: [],
};

const jobViewResponsiveSlice = createSlice({
    name: 'jobViewResponsive',
    initialState,
    reducers: {
        jobViewResponsiveData(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const { jobViewResponsiveData } = jobViewResponsiveSlice.actions;
export default jobViewResponsiveSlice.reducer;
