import { configureStore, combineReducers } from '@reduxjs/toolkit';
import jobFilterReducer from './reducers/jobFilterSlice';
import jobCreateReducer from './reducers/jobCreateSlice';
import getCommonApiReducer from './reducers/getCommonApiSlice';
import jobViewResponsiveReducer from './reducers/jobViewResponsiveSlice';

const rootReducer = combineReducers({
    jobFilter: jobFilterReducer,
    jobCreate: jobCreateReducer,
    getCommonApi: getCommonApiReducer,
    jobViewResponsive: jobViewResponsiveReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof rootReducer>;
export default store;
