import React, { useEffect } from 'react';
import closeIcon from 'src/assets/img/closeImg.png';

const ModalContentBlock = ({ onclose, children, isMoreClass = '', isModalOpen }: any) => {
    useEffect(() => {
        const body = document.body;
        body.style.overflow = isModalOpen ? 'hidden' : 'auto';
        return () => {
            body.style.overflow = 'auto';
        };
    }, [isModalOpen]);
    useEffect(() => {
        const handleEsc = (event: any) => {
            if (event.keyCode === 27) {
                onclose();
            }
        };

        if (isModalOpen) {
            document.addEventListener('keydown', handleEsc);
        }

        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, [isModalOpen]);
    return (
        <>
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className={`bg-white p-6 rounded-lg  max-h-[calc(100vh-30px)] relative min-w-[70%] xl:min-w-[50%] scrollbar-hide ${isMoreClass}`}>
                    <div className="absolute top-3 right-2 employee_detail">
                        <img src={closeIcon} alt="closeicon" className="h-6 w-6 cursor-pointer" onClick={() => onclose()} />
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};
export default ModalContentBlock;
